import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import {SERVER_API_URL} from '../../app.constants';
import {Compte, ICompte} from '../model/compte.model';
import {CompteBank, ICompteBank} from '../model/comptebank.model';
import {IProduit} from '../model/produit.model';
import {IDocument} from '../model/document.model';

type EntityResponseType = HttpResponse<IDocument>;
type EntityArrayResponseType = HttpResponse<IDocument[]>;

@Injectable({ providedIn: 'root' })
export class DocumentService {
  produits: IDocument[] = [];
  public resourceUrl = SERVER_API_URL + 'document';

  constructor(protected http: HttpClient) {}

  create(document: IDocument, id, token): Observable<EntityResponseType> {
    return this.http.post<IDocument>(this.resourceUrl + '/' + id + '/' + token, document, { observe: 'response' });
  }

  createcomptable(document: IDocument, id, token): Observable<EntityResponseType> {
    return this.http.post<IDocument>(this.resourceUrl + 'comptable/' + id + '/' + token, document, { observe: 'response' });
  }

  update(compte: IDocument): Observable<EntityResponseType> {
    return this.http.put<IDocument>(this.resourceUrl, compte, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IDocument>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  findByEntreprise(id: number): Observable<EntityArrayResponseType> {
    return this.http.get<IDocument[]>(`${this.resourceUrl}/entreprise/${id}`, { observe: 'response' });
  }

  findDocComptableByEntreprise(id: number): Observable<EntityArrayResponseType> {
    return this.http.get<IDocument[]>(`${this.resourceUrl}comptable/entreprise/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    return this.http.get<IDocument[]>(this.resourceUrl, {observe: 'response' });
  }

  delete(id: number, token: string): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}/${token}`, { observe: 'response' });
  }
}

import {IEntreprise} from './entreprise.model';

export interface ICompteBank {
  id?: number;
  devise?: string;
  compte?: string;
  solde1?: number;
  solde2?: number;
  solde3?: number;
  libelle?: string;
  entreprise?: IEntreprise;
}

export class CompteBank implements ICompteBank {
  constructor(public id?: number, public devise?: string,
              public compte?: string,
              public solde1?: number,
              public solde2?: number,
              public solde3?: number, public libelle?: string,
              public entreprise?: IEntreprise) {}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import {SERVER_API_URL} from '../../app.constants';
import {Facture, IFacture} from '../model/facture.model';

type EntityResponseType = HttpResponse<IFacture>;
type EntityArrayResponseType = HttpResponse<IFacture[]>;

@Injectable({ providedIn: 'root' })
export class FactureService {
  public resourceUrl = SERVER_API_URL + 'facture';
  factures: Facture[] = [];
  libelleTypePaiement = ['Espèce', 'Banque CHF', 'Banque EUR', 'Poste Banque CHF', 'Poste Banque EUR'];
  rates = {};

  constructor(protected http: HttpClient) {}

  create(facture: IFacture, id, token): Observable<EntityResponseType> {
    return this.http.put<IFacture>(this.resourceUrl + '/' + id + '/' + token, facture, { observe: 'response' });
  }

  update(facture: IFacture, id, token): Observable<EntityResponseType> {
    return this.create(facture, id, token);
  }

  find(id: number, token: string): Observable<EntityResponseType> {
    return this.http.get<IFacture>(`${this.resourceUrl}/${id}/${token}`, { observe: 'response' });
  }

  findRate(): Observable<any> {
    return this.http.get<any[]>(SERVER_API_URL + 'rate', { observe: 'response' });
  }

  launchS3(id: number, token: string): Observable<EntityResponseType> {
    return this.http.get<IFacture>(`${this.resourceUrl}/analyse/ia/s3/${id}/${token}`, { observe: 'response' });
  }

  winbiz(id: number, factureId: string, token: string, admin: String): Observable<EntityResponseType> {
    return this.http.get(`${this.resourceUrl}/winbiz/${id}/${factureId}/${token}/${admin}`, { observe: 'response' });
  }

  findByEntreprise(id: number, token: string): Observable<EntityArrayResponseType> {
    return this.http.get<IFacture[]>(`${this.resourceUrl}/all/${id}/${token}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    return this.http.get<IFacture[]>(this.resourceUrl, { observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }
}

import {Component, OnInit} from '@angular/core';
import {Chart} from 'chart.js';
import {CompteService} from '../../entities/compte/compte.service';
import {EntrepriseService} from '../../entities/entreprise/entreprise.service';
import {Router} from '@angular/router';
import {FactureService} from '../../entities/facture/facture.service';
import {TypeFacture} from '../../entities/model/enumerations/type-facture.model';
import {ProduitService} from '../../entities/produit/produit.service';
import {CompteBankService} from '../../entities/comptebank/compte.service';
import {ICompteBank} from '../../entities/model/comptebank.model';
import {IProduit} from '../../entities/model/produit.model';
import {StatutFacture} from "../../entities/model/enumerations/statut-facture.model";

let bars;
let barscharges;
let barsmassesalariales;
let achatvente;
let chargedepersonnel;
let totaltva;
let repartitioncomptes;
let creancedette;
let produitmontant;
let achat;
let vente;
let barsimpayes;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  achat = [];
  vente = [];
  currentDate = new Date();
  barsimpayes = {};
  totalAchat: any = 0;
  totalAchatAnneeEnCours = 0;
  totalVente: any = 0;
  totalVenteAnneeEnCours = 0;
  months = ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Dec'];

  // Tableau reprenant les soldes initiaux
  soldes_initiaux = {
    siAchatEspece: 0,
    siAchatBanqueEur: 0,
    siAchatBanqueChf: 0,
    siAchatCbEur: 0,
    siAchatCbChf: 0,
    siVenteEspece: 0,
    siVenteBanqueEur: 0,
    siVenteBanqueChf: 0,
    siVenteCbEur: 0,
    siVenteCbChf: 0,
    siAchatventeAchat: 0,
    siAchatventeVente: 0,
    siCreancedetteCreance: 0,
    siCreancedetteDette: 0
  };
  // Détails des comptes
  accounts: any = [];
  // Age moyen impayes
  ageMoyenImpayes = 0;
  // Taux evolution age moyen impayes
  tauxEvolutionAgeMoyen = 0;
  // Total somme paiement en attente
  sommePaiementAttente = 0;
  // Nombre d'écheance impayes
  echeancesImpayes = 0;
  // Delaie moyen paiement
  delaiMoyenPaiement = 0;
  // Evolution delai moyen paiement
  evolutionDelaiMoyenPaiement = 0;
  // Année selectionné pour le dashboard
  selectedYear = new Date().getFullYear();
  // Année disponible de selection du dashboard
  yearsOfDashboard = [];
  // Liste des comptes banks supplémentaires
  bankAccounts: ICompteBank[] = [];
  // Mois précédent
  moisprecedent = 'TEST';
  anneeEnCours = '';
  // Benefice du mois précédent
  beneficeMoisPrecedent = 0;
  totalSalaireMoisPrecedent = 0;
  beneficeTotalAnneeEnCours = 0;
  // Numero du mois précédent [1, 12]
  previousMonth = (this.currentDate.getMonth() === 0 ? 11 : this.currentDate.getMonth());
  // Année du mois précedent
  yearOfPreviousMonth = this.currentDate.getMonth() === 0 ? (this.currentDate.getFullYear() - 1) : this.currentDate.getFullYear();
  // Tableau des couleurs du chart de produit
  productsColorSeries = ['rgba(200,0,0,0.2)', 'rgba(0,200,0,0.2)', 'rgba(0,0,200,0.2)',
    'rgba(200,200,0,0.2)', 'rgba(200,200,0,0.2)', 'rgba(200,0,200,0.2)',
    'rgba(200,0,200,0.2)', 'rgba(0,200,200,0.2)', 'rgba(0,200,200,0.2)'];
  // Liste des produits de l'entreprise
  products: IProduit[] = [];
  // Difference de TVA
  differenceTVA = 0;
  // Affichage de la modal de configuration du dashboard
  displayTools: any = false;
  // Affichage des impayes
  impayesDisplayed: any;
  displayImpaye: any;
  displayProduct: any;
  displayDepenceca: any;
  displayMassesalariale: any;
  displayCr: any;
  displayDettetva: any;

  constructor(private compteService: CompteService,
              private router: Router,
              private productService: ProduitService,
              private bankAccountsService: CompteBankService,
              private factureService: FactureService,
              private entrepriseService: EntrepriseService) { }

  ngOnInit() {
    if (!this.entrepriseService.entreprise) {
      this.router.navigate(['/login']);
      return;
    }
    this.computeYearLabel();
    this.yearsOfDashboard = Array((this.selectedYear + 1) - 2018)
      .fill(2018)
      .map((e, i) => e + i).reverse();
    // Creation de la liste des comptes et ajout des montants
    this.accounts = this.compteService.comptes.slice().map((e: any) => {
      e.total = this.factureService.factures.reduce((acc, facture) => {
        if (facture.compte !== null) {
          if (+e.code === +facture.compte.code) {
            acc = acc + facture.montantTotal;
          }
        }
        if (facture.compteCredit !== null) {
          if (+e.code === +facture.compteCredit.code) {
            acc = acc + facture.montantTotal;
          }
        }
        return acc;
      }, 0);
      return e;
    });
    // Mise à jour des factures
    if (this.entrepriseService.entreprise) {
      this.factureService.findByEntreprise(this.entrepriseService.entreprise.id,
        this.entrepriseService.entreprise.token).subscribe((factures) => {
        this.factureService.factures = factures.body.filter(e => e.statut.toString() !== 'ARCHIVED' );
      });
      // Recuperation des soldes initiaux si l'année de saisie est l'année en cours
      if (this.entrepriseService.entreprise.siAnnee
        && this.entrepriseService.entreprise.siAnnee === new Date().getFullYear()) {
        Object.keys(this.soldes_initiaux).forEach(si => {
          this.soldes_initiaux[si] = this.entrepriseService.entreprise[si];
        });
      }
    }
    this.setCheboxStateDashboard();
    this.bankAccountsService.findByEntreprise(this.entrepriseService.entreprise.id).subscribe(bankAccounts => {
      if (bankAccounts.body && bankAccounts.body.length > 0) {
        this.bankAccounts = bankAccounts.body;
      }
      // Calcul délai moyen paiement
      const totalFactureImpayesVente = this.getFactureVentePayes();
      const totalDateImpayeFactureVente = totalFactureImpayesVente.reduce((acc, e) => {
        acc = acc + this.getNumberOfDaysBetween(this.convertDate(e.dateFacture), this.convertDate(e.datePaiement));
        return acc;
      }, 0);
      this.delaiMoyenPaiement = Math.ceil(totalDateImpayeFactureVente / totalFactureImpayesVente.length);
      this.delaiMoyenPaiement = isNaN(this.delaiMoyenPaiement) ? 0 : this.delaiMoyenPaiement;
      // Calcul Age moyen des impayes
      const totalFactureImpayesAchat = this.getFactureAchatPayes();
      let totalDateImpayeFactureAchat = 0;
      totalFactureImpayesAchat.map(e => {
        totalDateImpayeFactureAchat += this.getNumberOfDaysBetween(this.convertDate(e.dateFacture), this.convertDate(e.datePaiement));
      });
      this.ageMoyenImpayes = Math.ceil(totalDateImpayeFactureAchat / totalFactureImpayesAchat.length);
      this.ageMoyenImpayes = isNaN(this.ageMoyenImpayes) ? 0 : this.ageMoyenImpayes;
      this.productService.findByEntreprise(this.entrepriseService.entreprise.id).subscribe(
        (products) => {
          this.products = products.body;
          this.updateDashboard();
        }
      );
    });
  }

  private computeYearLabel() {
    // Compute mois précédent
    if (this.currentDate) {
      this.moisprecedent = this.months[this.previousMonth - 1] + ' ' + this.selectedYear;
      this.anneeEnCours = this.currentDate.getFullYear().toString();
    }
  }

  /**
   * Récupere le montant total des factures d'un type pour un type de paiement
   */
  getTotalTypePaiement(type: TypeFacture, paiement: string) {
    return this.factureService.factures.slice()
    // @ts-ignore
      .filter(e => e.type === TypeFacture[type])
      .filter(e => e.typePaiement === paiement)
      .filter(e => e.dateFacture && +e.dateFacture.slice(6, 10) === this.selectedYear)
      .reduce((acc, e) => {
        acc = acc + e.montantTotal;
        return acc;
      }, 0);
  }

  /**
   * Récupere toutes les factures d'achat
   */
  getFactureAchat() {
    // @ts-ignore
    return this.factureService.factures.slice().filter(e => e.type === 'Achat');
  }

  /**
   * Récupere toutes les factures de vente
   */
  getFactureVente() {
    // @ts-ignore
    return this.factureService.factures.slice().filter(e => e.type === 'Vente');
  }

  /**
   * Récupere toutes les factures de ventes non payées
   */
  getFactureVenteImpayes() {
    return this.getFactureVente().filter(e => e.datePaiement == null || e.datePaiement === '');
  }

  /**
   * Récupere toutes les factures de ventes non payées
   */
  getFactureAchatImpayes() {
    return this.getFactureAchat().filter(e => e.datePaiement == null || e.datePaiement === '');
  }

  /**
   * Récupere toutes les factures de ventes payées
   */
  getFactureVentePayes() {
    return this.getFactureVente().filter(e => e.datePaiement !== null && e.datePaiement !== '');
  }


  /**
   * Récupere toutes les factures de achats payées
   */
  getFactureAchatPayes() {
    return this.getFactureAchat().filter(e => e.datePaiement !== null && e.datePaiement !== '');
  }

  /**
   * Récupere les impayes des 30 derniers jours
   */
  getFactureVenteImpayesMoins30j() {
    return this.getFactureVenteImpayes().filter(e => {
      if (this.getNumberOfDaysBetween(this.convertDate(e.dateFacture), this.currentDate) <= 30) {
        return e;
      }
    });
  }

  /**
   * Récupere les impayes entre les 30 et 60 derniers jours
   */
  getFactureVenteImpayes30jA60j() {
    let dayBetween;
    return this.getFactureVenteImpayes().filter(e => {
      dayBetween = this.getNumberOfDaysBetween(this.convertDate(e.dateFacture), this.currentDate);
      if (dayBetween >= 31 && dayBetween <= 60) {
        return e;
      }
    });
  }

  /**
   * Récupere toutes les factures de taxe
   */
  getFactureTaxe() {
    // @ts-ignore
    return this.factureService.factures.slice().filter(e => e.type === TypeFacture[TypeFacture.Taxe]);
  }

  /**
   * Récupere toutes les factures de salaire
   */
  getFactureSalaire() {
    // @ts-ignore
    return this.factureService.factures.slice().filter(e => e.type === TypeFacture[TypeFacture.Salaire]);
  }

  /**
   * Récupere la liste des mois à afficher
   */
  getMonthsLabel() {
    let tmpMonths = this.months.slice();
    tmpMonths = tmpMonths.map((e, i) => {
      return e + ' ' + (this.selectedYear - 2000);
    });
    return tmpMonths;
  }

  /**
   * Récupere les dates pour le traitement des bars
   */
  getDateForComputeBars() {
    const tmpMonthsLabel = this.getMonthsLabel();
    return tmpMonthsLabel.slice()
      .map((e, i) =>
        e.split(' ')[0]
          .replace('Jan', '01')
          .replace('Fév', '02')
          .replace('Mars', '03')
          .replace('Avr', '04')
          .replace('Mai', '05')
          .replace('Juin', '06')
          .replace('Juil', '07')
          .replace('Août', '08')
          .replace('Sept', '09')
          .replace('Oct', '10')
          .replace('Nov', '11')
          .replace('Dec', '12')
        + '/20' +
        e.split(' ')[1]);
  }

  computeBarImpayesDetails() {
    const totalImpayes = this.getFactureVenteImpayes();
    const datas = [
      {
        label: 'Achat',
        backgroundColor: '#9c9cff',
        data: [
          // Entre 0 et 30 jours
          this.getTotalImpayesByDays(totalImpayes, 0, 30),
          // Entre 31 et 60 jours
          this.getTotalImpayesByDays(totalImpayes, 31, 60),
          // Entre 61 et 90 jours
          this.getTotalImpayesByDays(totalImpayes, 61, 90),
          // Plus de 91 jours
          this.getTotalImpayesByDays(totalImpayes, 91)]
      }];

    return datas;
  }

  /**
   * Retourne le montant total des impayés entre le jours min et le jour max
   * @param totalImpayes : tableaux des factures impayés
   * @param dayMin : jour min
   * @param dayMax : jour max
   */
  getTotalImpayesByDays(totalImpayes, dayMin, dayMax?) {
    let numberOfDays;
    const impaye0A30j = [];
    const impaye31A60j = [];
    this.echeancesImpayes = totalImpayes.length;
    const totals = totalImpayes.reduce((acc, e) => {
      numberOfDays = this.getNumberOfDaysBetween(this.convertDate(e.dateFacture), this.currentDate);
      if (dayMin === 0 && dayMax === 30) {
        this.sommePaiementAttente += (e.montantTotal && +e.montantTotal > 0) ? (Math.round(e.montantTotal)) : 0;
        if (numberOfDays >= 31 && numberOfDays <= 60) {
          impaye31A60j.push(numberOfDays);
        }
        this.sommePaiementAttente = isNaN(this.sommePaiementAttente) ? 0 : this.sommePaiementAttente;
      }
      if (numberOfDays >= dayMin && (dayMax ? (numberOfDays <= dayMax) : true) && e.montantTotal) {
        acc = acc + e.montantTotal;
        if (dayMin === 0 && dayMax === 30) {
          impaye0A30j.push(numberOfDays);
        }
      }
      return acc;
    }, 0);
    if (dayMin === 0 && dayMax === 30) {
      const ageImpaye0A30 = impaye0A30j.reduce((acc, e) => {
        acc = acc + e;
        return acc;
      }, 0);
      const ageImpaye31A60 = impaye31A60j.reduce((acc, e) => {
        acc = acc + e;
        return acc;
      }, 0);
      // this.ageMoyenImpayes = Math.ceil( (ageImpaye0A30 + ageImpaye31A60) / (impaye0A30j.length + impaye31A60j.length));
      const txImpaye0A30 = impaye0A30j.length === 0 ? 0 : (ageImpaye0A30 / impaye0A30j.length);
      const txImpaye31A60 = impaye31A60j.length === 0 ? 0 : (ageImpaye31A60 / impaye31A60j.length);
      if (txImpaye0A30 === 0 || txImpaye31A60 === 0) {
        this.tauxEvolutionAgeMoyen = 0;
      } else {
        this.tauxEvolutionAgeMoyen = Math.ceil(txImpaye0A30 / txImpaye31A60);
      }
      if (isNaN(this.tauxEvolutionAgeMoyen)) {
        this.tauxEvolutionAgeMoyen = 0;
      }
      if (isNaN(this.ageMoyenImpayes)) {
        this.ageMoyenImpayes = 0;
      }
    }
    return totals;
  }

  /**
   * Crée la dataset des bars Achat/vente/Benef
   */
  private computeBarDataset() {
    const datas = [
      {
        label: 'Achat',
        backgroundColor: '#ff4c4c',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      {
        label: 'Vente',
        backgroundColor: '#489c72',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      {
        label: 'Bénéfice du mois',
        backgroundColor: '#3498db',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      {
        label: 'Bénéfices cumulés',
        backgroundColor: '#ef0aef',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    ];
    const dates = this.getDateForComputeBars();
    let indiceOfDateInData;
    this.factureService.factures.forEach(e => {
      if (e.dateFacture && +e.dateFacture.slice(6, 10) === this.selectedYear) {
        indiceOfDateInData = dates.indexOf(e.dateFacture.slice(3, 10));
        if (e.type.toString() === 'Achat') {
          datas[0].data[indiceOfDateInData] += e.montantTotal;
          datas[2].data[indiceOfDateInData] -= e.montantTotal;
        } else if (e.type.toString() === 'Vente') {
          datas[1].data[indiceOfDateInData] += e.montantTotal;
          datas[2].data[indiceOfDateInData] += e.montantTotal;
        }
      }
    });
    // Calcule des benefices cumulés
    for (let i = 0; i <= 11; i++) {
      for (let j = 0; j <= i; j++) {
        datas[3].data[i] += datas[2].data[j];
      }
    }
    return datas;
  }

  /**
   * Crée la dataset du graphique des bars par charge
   */
  computeBarChargeDataset() {
    const datas = [
      {
        label: 'Charges de prestations de services',
        backgroundColor: '#ff4c4c',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      {
        label: 'Achat de matériels',
        backgroundColor: '#489c72',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      {
        label: 'Achat de marchandise',
        backgroundColor: '#3498db',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    ];
    const dates = this.getDateForComputeBars();
    const dettesPrestationService = this.compteService.comptes.find(e => +e.code === 4400 );
    const dettesAchatMateriel = this.compteService.comptes.find(e => +e.code === 4000 );
    const dettesAchatMarchandise = this.compteService.comptes.find(e => +e.code === 4200 );
    let indiceOfDateInData;
    this.factureService.factures.forEach(e => {
      if (e.dateFacture && +e.dateFacture.slice(6, 10) === this.selectedYear) {
        indiceOfDateInData = dates.indexOf(e.dateFacture.slice(3, 10));
        if (dettesPrestationService && e.type.toString() === 'Achat' &&
          ((e.compteCredit && +e.compteCredit.code === +dettesPrestationService.code) || (e.compte && +e.compte.code === +dettesPrestationService.code))) {
          datas[0].data[indiceOfDateInData] += e.montantTotal;
        } else if (dettesAchatMateriel && e.type.toString() === 'Achat' &&
          ((e.compteCredit && +e.compteCredit.code === +dettesAchatMateriel.code) || (e.compte && +e.compte.code === +dettesAchatMateriel.code))) {
          datas[1].data[indiceOfDateInData] += e.montantTotal;
        } else if (dettesAchatMarchandise && e.type.toString() === 'Achat' &&
          ((e.compteCredit && +e.compteCredit.code === +dettesAchatMarchandise.code) || (e.compte && +e.compte.code === +dettesAchatMarchandise.code))) {
          datas[2].data[indiceOfDateInData] += e.montantTotal;
        }
      }
    });
    return datas;
  }

  /**
   * Crée la dataset du graphique des bars par charge
   */
  computeBarMasseSalarialeDataset() {
    const datas = [
      {
        label: 'Masse salariale',
        backgroundColor: '#ff4c4c',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }, {
        label: 'Moyenne',
        color: '#000000',
        fill: false,
        borderColor: 'rgba(0,0,0,0.6)',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        type: 'line'
      }
    ];
    const dates = this.getDateForComputeBars();
    let total = 0;
    let indiceOfDateInData;
    let amount;
    this.factureService.factures.forEach(e => {
      if (e.dateFacture && +e.dateFacture.slice(6, 10) === this.selectedYear &&  e.type.toString() === 'Salaire') {
        indiceOfDateInData = dates.indexOf(e.dateFacture.slice(3, 10));
        amount = e.devise === 'EUR' ? Math.round(e.montantTotal / 0.9) : e.montantTotal;
        datas[0].data[indiceOfDateInData] += amount;
        total += amount;
      }
    });
    datas[1].data.fill(Math.round(total / 12));
    return datas;
  }

  /**
   * Récupere la somme des factures de dettes resultants d'achat (Facture achat non payé)
   */
  getTotalDetteResultantAchat() {
    return Math.round(100 * this.getFactureAchat().reduce((acc, e) => {
      if (!e.datePaiement || e.datePaiement === '') {
        acc = acc + e.montantTotal;
      }
      return acc;
    }, 0)) / 100;
  }

  /**
   * Retourne le montant total des creances clients (factures de ventes non payées)
   */
  getTotalCreanceClient() {
    return Math.round(100 * this.getFactureVente().reduce((acc, e) => {
      if (!e.datePaiement || e.datePaiement === '') {
        acc = acc + e.montantTotal;
      }
      return acc;
    }, 0)) / 100;
  }

  /**
   * Converti le string de date en objet Date
   * @param inputFormat
   */
  convertDate(inputFormat) {
    if (inputFormat == null) {
      return new Date();
    }
    const arrDate = inputFormat.split('/');
    return new Date([arrDate[1], arrDate[0], arrDate[2]].join('/'));
  }

  /**
   * Retourne le nombre de jour d'ecart entre les deux objets dates
   * @param first: Date
   * @param second: Date
   */
  getNumberOfDaysBetween(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  /**
   * Recharge les données du dashboard
   */
  updateDashboard() {
    if (bars) {
      bars.destroy();
      barscharges.destroy();
      barsmassesalariales.destroy();
      achatvente.destroy();
      creancedette.destroy();
      chargedepersonnel.destroy();
      totaltva.destroy();
      produitmontant.destroy();
      achat.destroy();
      vente.destroy();
      barsimpayes.destroy();
    }
    this.totalAchat = Math.round(100 * this.getFactureAchat().reduce((acc, e) => {
      acc = acc + e.montantTotal;
      return acc;
    }, 0)) / 100;
    this.totalAchatAnneeEnCours = Math.round(100 * this.getFactureAchat().reduce((acc, e) => {
      if (new Date(e.datePaiement).getFullYear() === this.selectedYear) {
        acc = acc + e.montantTotal;
      }
      return acc;
    }, 0)) / 100;
    this.totalVente =  Math.round(100 * this.getFactureVente().reduce((acc, e) => {
      acc = acc + e.montantTotal;
      return acc;
    }, 0)) / 100;
    this.totalVenteAnneeEnCours = Math.round(100 * this.getFactureVente().reduce((acc, e) => {
      if (new Date(e.datePaiement).getFullYear() === this.selectedYear) {
        acc = acc + e.montantTotal;
      }
      return acc;
    }, 0)) / 100;
    // Calcul la moyenne des taux de TVA sur l'année
    const arrayTauxTvaAchat = this.getFactureAchat()
      .filter(e => e.datePaiement && new Date(e.datePaiement).getFullYear() === this.selectedYear);
    const totalTauxTvaAchat =
      arrayTauxTvaAchat.reduce((acc, e) => acc + (1 - (+e.taxe / 100)), 0) /
      (arrayTauxTvaAchat.length === 0 ? 1 : arrayTauxTvaAchat.length);
    // Calcul la moyenne des taux de TVA sur l'année
    const arrayTauxTvaVente = this.getFactureVente()
      .filter(e => e.datePaiement && new Date(e.datePaiement).getFullYear() === this.selectedYear);
    const totalTauxTvaVente =
      arrayTauxTvaVente.reduce((acc, e) => acc + (1 - (+e.taxe / 100)), 0) /
      (arrayTauxTvaVente.length === 0 ? 1 : arrayTauxTvaVente.length);
    // Construit les données du pie chart d'achat
    achat = new Chart('achat', {
      type: 'pie',
      data: {
        labels: ['Espèce', 'Banque CHF', 'Banque EUR', 'Poste Banque CHF', 'Poste Banque EUR'].concat(this.bankAccounts.map(e => e.libelle)),
        datasets: [{
          backgroundColor: ['#eb983c', '#ff4c4c', '#3498db', '#975192', '#489c72', '#9c4872', '#489c48', '#487272', '#729c72', '#999c72', '#489c9c'],
          data: [
            this.getTotalTypePaiement(TypeFacture.Achat, this.factureService.libelleTypePaiement[0])
            + this.soldes_initiaux.siAchatEspece,
            this.getTotalTypePaiement(TypeFacture.Achat, this.factureService.libelleTypePaiement[1])
            + this.soldes_initiaux.siAchatBanqueChf,
            this.getTotalTypePaiement(TypeFacture.Achat, this.factureService.libelleTypePaiement[2])
            + this.soldes_initiaux.siAchatBanqueEur,
            this.getTotalTypePaiement(TypeFacture.Achat, this.factureService.libelleTypePaiement[3])
            + this.soldes_initiaux.siAchatCbChf,
            this.getTotalTypePaiement(TypeFacture.Achat, this.factureService.libelleTypePaiement[4])
            + this.soldes_initiaux.siAchatCbEur].concat(this.bankAccounts.map(e => {
            return this.getTotalTypePaiement(TypeFacture.Achat, e.libelle) + (+e.solde1);
          })),
          borderWidth: [0, 0, 0, 0, 0],
        }]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontFamily : 'Poppins',
            fontColor: '#273646',
            fontStyle: '300',
            lineHeight: '2'
          },
          position: 'right'
        },
        title: {
          fontFamily : 'Poppins',
          display: true,
          text: 'Dépenses ' + this.selectedYear,
          fontColor: '#273646',
          fontSize: '17',
          lineHeight: '2'
        },
      }
    });
    // Construit les données du pie chart de vente
    vente = new Chart('vente', {
      type: 'pie',
      data: {
        labels: ['Espèce', 'Banque CHF', 'Banque EUR', 'Poste Banque CHF', 'Poste Banque EUR'].concat(this.bankAccounts.map(e => e.libelle)),
        datasets: [{
          backgroundColor: ['#eb983c', '#ff4c4c', '#3498db', '#975192', '#489c72', '#9c4872', '#489c48', '#487272', '#729c72', '#999c72', '#489c9c'],
          data: [
            this.getTotalTypePaiement(TypeFacture.Vente, this.factureService.libelleTypePaiement[0])
            + this.soldes_initiaux.siVenteEspece,
            this.getTotalTypePaiement(TypeFacture.Vente, this.factureService.libelleTypePaiement[1])
            + this.soldes_initiaux.siVenteBanqueChf,
            this.getTotalTypePaiement(TypeFacture.Vente, this.factureService.libelleTypePaiement[2])
            + this.soldes_initiaux.siVenteBanqueEur,
            this.getTotalTypePaiement(TypeFacture.Vente, this.factureService.libelleTypePaiement[3])
            + this.soldes_initiaux.siVenteCbChf,
            this.getTotalTypePaiement(TypeFacture.Vente, this.factureService.libelleTypePaiement[4])
            + this.soldes_initiaux.siVenteCbEur].concat(this.bankAccounts.map(e => {
            return this.getTotalTypePaiement(TypeFacture.Vente, e.libelle) + (+e.solde2);
          })),
          borderWidth: [0, 0, 0, 0, 0]
        }]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontFamily : 'Poppins',
            fontColor: '#273646',
            fontStyle: '300',
            lineHeight: '2'
          },
          position: 'right'
        },
        title: {
          display: true,
          text: 'Chiffre d\'affaire ' + this.selectedYear,
          fontFamily : 'Poppins',
          fontColor: '#273646',
          fontSize: '17',
          lineHeight: '2'
        }
      }
    });
    // Construit le diagramme en bar d'impayes
    barsimpayes = new Chart('barsimpayes', {
      type: 'horizontalBar',
      data: {
        labels: ['De 0 à 30 jours', 'De 31 à 60 jours', 'De 61 à 90 jours', 'Plus de 90 jours'],
        datasets: this.computeBarImpayesDetails()
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false,
          legendText : ['Current', 'Vs last week/month/year', '% Change']
        },
        title: {
          display: true,
          text: 'Détails des impayés',
          fontFamily : 'Poppins',
          fontColor: '#273646',
          fontSize: '17',
          lineHeight: '2'
        }
      }
    });
    // Construit le diagramme en bar Achat/Vente/benef
    bars = new Chart('bars', {
      type: 'bar',
      data: {
        labels: this.getMonthsLabel(),
        datasets: this.computeBarDataset()
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontFamily : 'Poppins',
            fontColor: '#273646',
            fontStyle: '300',
            lineHeight: '2'
          },
        },
        title: {
          display: true,
          text: 'Compte de résultat année en cours (' + this.selectedYear + ')',
          fontFamily : 'Poppins',
          fontColor: '#273646',
          fontSize: '17',
          lineHeight: '2'
        }
      }
    });
    // Construit le graphique des charges par mois
    barscharges = new Chart('barscharges', {
      type: 'bar',
      data: {
        labels: this.getMonthsLabel(),
        datasets: this.computeBarChargeDataset()
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontFamily : 'Poppins',
            fontColor: '#273646',
            fontStyle: '300',
            lineHeight: '2'
          },
        },
        title: {
          display: true,
          text: 'Evolution par mois des charges (' + this.selectedYear + ')',
          fontFamily : 'Poppins',
          fontColor: '#273646',
          fontSize: '17',
          lineHeight: '2'
        }
      }
    });
    // Construit le graphique des charges par mois
    barsmassesalariales = new Chart('barsmassesalariales', {
      type: 'bar',
      data: {
        labels: this.getMonthsLabel(),
        datasets: this.computeBarMasseSalarialeDataset()
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false,
          labels: {
            fontFamily : 'Poppins',
            fontColor: '#273646',
            fontStyle: '300',
            lineHeight: '2'
          },
        },
        elements: {
          point: {
            radius: 0
          }
        },
        title: {
          display: true,
          text: 'Evolution masse salariale (' + this.selectedYear + ')',
          fontFamily : 'Poppins',
          fontColor: '#273646',
          fontSize: '17',
          lineHeight: '2'
        }
      }
    });
    // Construit le graphique des comptes
    const bestAccount = this.accounts.sort((acc1, acc2) => {
      if (+acc1.total - acc2.total) {
        return 1;
      } else if (acc1 === acc2) {
        return 0;
      }
      return -1;
    }).slice(0, 5).reverse();
    repartitioncomptes = new Chart('repartitioncomptes', {
      type: 'bubble',
      data: {
        datasets: [{
          label: bestAccount[0].libelle,
          data: [{
            x: 100,
            y: 0,
            r: 0
          }, {
            x: 60,
            y: 30,
            r: 20
          }, {
            x: 40,
            y: 60,
            r: 25
          }, {
            x: 80,
            y: 80,
            r: 50
          }, {
            x: 20,
            y: 30,
            r: 25
          }, {
            x: 10,
            y: 100,
            r: 0
          }],
          backgroundColor: '#FF9966'
        }]
      }
    });
    // Construit les données du pie chart d'achat/vente
    achatvente = new Chart('achatvente', {
      type: 'pie',
      data: {
        labels: ['Dépenses', 'Chiffre d\'affaire'],
        datasets: [{
          backgroundColor: ['#6666eb', '#273646'],
          data: [
            this.totalAchatAnneeEnCours + this.soldes_initiaux.siAchatventeAchat,
            this.totalVenteAnneeEnCours + this.soldes_initiaux.siAchatventeVente
          ],
        }]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontFamily : 'Poppins',
            fontColor: '#273646',
            fontStyle: '300',
            lineHeight: '2'
          },
          position: 'right'
        },
        title: {
          fontFamily : 'Poppins',
          display: true,
          text: 'Dépenses / Chiffre d\'affaire de ' + this.selectedYear,
          fontColor: '#273646',
          fontSize: '17',
          lineHeight: '2'
        },
      }
    });
    // Construit les données du pie chart d'achat/vente
    creancedette = new Chart('creancedette', {
      type: 'pie',
      data: {
        labels: ['Creance', 'Dette'],
        datasets: [{
          backgroundColor: ['#66deed', '#10d09a'],
          data: [
            this.getTotalCreanceClient() + this.soldes_initiaux.siCreancedetteCreance,
            this.getTotalDetteResultantAchat() + this.soldes_initiaux.siCreancedetteDette
          ],
        }]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontFamily : 'Poppins',
            fontColor: '#273646',
            fontStyle: '300',
            lineHeight: '2'
          },
          position: 'right'
        },
        title: {
          fontFamily : 'Poppins',
          display: true,
          text: 'Creances / dettes',
          fontColor: '#273646',
          fontSize: '17',
          lineHeight: '2'
        },
      }
    });
    // Construit les données du pie chart des charges de personnel
    chargedepersonnel = new Chart('chargedepersonnel', {
      type: 'pie',
      data: {
        labels: ['Salaire CHF', 'Salaire EUR', 'Charges salariales', 'Autres charges de personnel', 'Impot à la source'],
        datasets: [{
          backgroundColor: ['#66deed', '#10d09a', '#ff4c4c', '#489c72', '#475129'],
          data: [
            this.getTotalSalaireSelectedYearByCurrency('CHF'),
            this.getTotalSalaireSelectedYearByCurrency('EUR'),
            [4310, 4370, 4382, 4386, 4387].reduce((acc, e) => {
              return acc + this.getTotalTaxeSelectedYearForAccountCode(e);
            }, 0),
            this.getTotalTaxeSelectedYearForAccountCode(5890),
            this.getTotalTaxeSelectedYearForAccountCode(2279),
          ],
        }]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontFamily : 'Poppins',
            fontColor: '#273646',
            fontStyle: '300',
            lineHeight: '2'
          },
          position: 'right'
        },
        title: {
          fontFamily : 'Poppins',
          display: true,
          text: 'Répartition des charges de personnels ' + this.selectedYear,
          fontColor: '#273646',
          fontSize: '17',
          lineHeight: '2'
        },
      }
    });
    // Construit le chart des produits montants
    produitmontant = new Chart('produitmontant', {
        type: 'radar',
        data: {
          labels: this.months,
          datasets: this.products.map((e, i) => {
            return {
              label: e.libelle,
              backgroundColor: this.productsColorSeries[i],
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map((f, j) =>
                this.getTotalVenteProduitFor(j + 1, this.selectedYear, e.id))
            };
          }).filter(e => {
            if (e.data && e.data.length && e.data.length > 0) {
              if (e.data.reduce((a, b) => a + b, 0) > 0) {
                return true;
              }
            }
            return false;
          })
        },
        options: {
          scale: {
            ticks: {
              min: 0
            }
          },
          legend: {
            title: {
              fontFamily: 'Poppins',
              display: true,
              text: 'Montant par produit',
              fontColor: '#273646',
              fontSize: '17',
              lineHeight: '2'
            },
          }
        }
      }
    );
    // Construit les données du pie chart d'achat/vente
    const tvaAchat = Math.round(100 * this.getFactureAchat().reduce((acc, e) => {
        if (new Date(e.datePaiement).getFullYear() === this.selectedYear && e.taxe !== 0) {
          acc = acc + e.montantTotal * (e.taxe / 100);
        }
        return acc;
      }, 0)
      ) / 100;
    const tvaVente = Math.round(100 * this.getFactureVente().reduce((acc, e) => {
      if (new Date(e.datePaiement).getFullYear() === this.selectedYear && e.taxe !== 0) {
        acc = acc + e.montantTotal * (e.taxe / 100);
      }
      return acc;
    }, 0)
    ) / 100;
    this.differenceTVA =
        Math.round(tvaAchat) -
      Math.round(tvaVente);
    totaltva = new Chart('totaltva', {
      type: 'pie',
      data: {
        labels: ['TVA Achat', 'TVA Vente'],
        datasets: [{
          backgroundColor: ['#10d09a', '#ff7c7c'],
          data: [tvaAchat, tvaVente]
        }]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontFamily : 'Poppins',
            fontColor: '#273646',
            fontStyle: '300',
            lineHeight: '2'
          },
          position: 'right'
        },
        title: {
          fontFamily : 'Poppins',
          display: true,
          text: 'TVA ',
          fontSize: '17',
          lineHeight: '2'
        },
      }
    });

    this.computeYearLabel();

    // Met a jour les trois carrés d'indices du dashboard
    this.beneficeMoisPrecedent = this.getTotalBeneficeByMonthYearInCHF(
      this.previousMonth,
      this.selectedYear
    );
    this.beneficeTotalAnneeEnCours = this.getTotalBeneficForYear();
    if (this.currentDate) {
      this.totalSalaireMoisPrecedent = this.getTotalSalaireByMonthYearInCHF(
        this.previousMonth,
        this.selectedYear
      );
    }
  }

  getTotalBeneficForYear() {
    const datas = this.computeBarDataset();
    if (!datas || datas.length < 2 || !datas[2].data) {
      return 0;
    }
    return Math.round((datas[3].data[11]) || 0);
  }

  /**
   * Récupere le total des salaires pour la currency passée en parametre
   * @param currency
   */
  getTotalSalaireSelectedYearByCurrency(currency): number {
    return Math.round(this.getFactureSalaire()
      .filter(e => e.dateFacture && +e.dateFacture.slice(6, 10) === this.selectedYear)
      .reduce((acc, e) => {
        if (e.devise === currency) {
          acc = acc + e.montantTotal;
        }
        return acc;
      }, 0));
  }

  /**
   * Récupere le total des salaires en CHF pour le mois et l'année en parametre
   * @param month
   * @param year
   */
  getTotalSalaireByMonthYearInCHF(month, year): number {
    return Math.round(this.getFactureSalaire()
      .filter(e => e.dateFacture && +e.dateFacture.slice(6, 10) === year)
      .filter(e => e.dateFacture && +e.dateFacture.slice(3, 5) === month)
      .reduce((acc, e) => acc + e.montantTotal * (e.devise === 'EUR' ? 1.1 : 1), 0));
  }

  /**
   * Recupere le benefice / perte du mois et de l'année passée en parametre
   * @param month
   * @param year
   */
  getTotalBeneficeByMonthYearInCHF(month, year): number {
    const datas = this.computeBarDataset();
    if (!datas || datas.length < 2 || !datas[2].data) {
      return 0;
    }
    return Math.round((datas[2].data[(+month) - 1]) || 0);
  }

  /**
   * Récupere le total des ventes des produits pour le mois et année
   * @param month
   * @param year
   * @param productId
   */
  getTotalVenteProduitFor(month, year, productId): number {
    return Math.round(this.getFactureVente()
      .filter(e => e.dateFacture && +e.dateFacture.slice(6, 10) === year)
      .filter(e => e.dateFacture && +e.dateFacture.slice(3, 5) === month)
      .filter(e => e.produit && +e.produit.id === +productId)
      .reduce((acc, e) => acc + e.montantTotal * (e.devise === 'EUR' ? 1.1 : 1), 0));
  }

  /**
   * Recupere le total des factures de taxe pour le code de compte passé
   * @param accountCode
   */
  getTotalTaxeSelectedYearForAccountCode(accountCode) {
    return Math.round(this.getFactureTaxe().filter(e => e.dateFacture && +e.dateFacture.slice(6, 10) === this.selectedYear)
      .reduce((acc, e) => {
        if ((e.compte && +e.compte.code === +accountCode) ||
          (e.compteCredit && +e.compteCredit.code === +accountCode)) {
          acc = acc + e.montantTotal;
        }
        return acc;
      }, 0));
  }

  /**
   * Enregistre l'entreprise
   */
  saveEntreprise() {
    this.entrepriseService.entreprise.displayCr = this.displayCr ? 1 : 0;
    this.entrepriseService.entreprise.displayMassesalariale = this.displayMassesalariale ? 1 : 0;
    this.entrepriseService.entreprise.displayDepenceca = this.displayDepenceca ? 1 : 0;
    this.entrepriseService.entreprise.displayProduct = this.displayProduct ? 1 : 0;
    this.entrepriseService.entreprise.displayImpaye = this.displayImpaye ? 1 : 0;
    this.entrepriseService.entreprise.displayDettetva = this.displayDettetva ? 1 : 0;
    this.updateDashboard();
    this.entrepriseService.save(this.entrepriseService.entreprise,
      this.entrepriseService.entreprise.id, this.entrepriseService.entreprise.token).subscribe((entreprisebody) => {
      this.entrepriseService.entreprise = entreprisebody.body;
    });
  }

  /**
   * Affiche la modal de configuration du dashboard
   */
  displayToolsFunction() {
    this.setCheboxStateDashboard();
    this.displayTools = true;
  }

  /**
   * Met le statut des checkbox sur le dashboard
   */
  setCheboxStateDashboard() {
    if (this.entrepriseService.entreprise) {
      this.displayCr = this.entrepriseService.entreprise.displayCr === 1;
      this.displayMassesalariale = this.entrepriseService.entreprise.displayMassesalariale === 1;
      this.displayDepenceca = this.entrepriseService.entreprise.displayDepenceca === 1;
      this.displayProduct = this.entrepriseService.entreprise.displayProduct === 1;
      this.displayImpaye = this.entrepriseService.entreprise.displayImpaye === 1;
      this.displayDettetva = this.entrepriseService.entreprise.displayDettetva === 1;
    }
  }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from "jquery";
import {EntrepriseService} from "./entities/entreprise/entreprise.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'brandless';
  constructor(public router: Router, public entrepriseService: EntrepriseService) {}

  openmobnav(){
    $(".mobnav_backdrop").css("display","flex");
    $(".side_nav").css("left","0px")
  }

  closemobnav(){
    $(".side_nav").css("left","-200px")
    $(".mobnav_backdrop").hide();
  }
}

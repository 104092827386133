import { Pipe, PipeTransform } from '@angular/core';
import {Facture} from "./entities/model/facture.model";

@Pipe({
  name: 'trimesterFactureFilter'
})
export class TrimesterFactureFilterPipe implements PipeTransform {

  transform(factures: Facture[], trimester?: any): any {
    const year = new Date().getFullYear();
    return factures.filter(facture => {
      if (!facture.datePaiement || facture.datePaiement === '') {
        if (trimester === '0') {
          return true;
        }
        return false;
      } else if (trimester === '1' && (facture.datePaiement.indexOf('/01/' + year) !== -1
        || facture.datePaiement.indexOf('/02/' + year) !== -1 ||
        facture.datePaiement.indexOf('/03/' + year) !== -1)) {
        return true;
      } else if (trimester === '2' && (facture.datePaiement.indexOf('/04/' + year) !== -1
        || facture.datePaiement.indexOf('/05/' + year) !== -1 ||
        facture.datePaiement.indexOf('/06/' + year) !== -1)) {
        return true;
      } else if (trimester === '3' && (facture.datePaiement.indexOf('/07/' + year) !== -1
        || facture.datePaiement.indexOf('/08/' + year) !== -1 ||
        facture.datePaiement.indexOf('/09/' + year) !== -1)) {
        return true;
      } else if (trimester === '4' && (facture.datePaiement.indexOf('/10/' + year) !== -1
        || facture.datePaiement.indexOf('/11/' + year) !== -1 ||
        facture.datePaiement.indexOf('/12/' + year) !== -1)) {
        return true;
      } else if (trimester === '-' && facture.datePaiement.indexOf('' + year) === -1) {
        return true;
      }
      if (trimester === '-' && facture.datePaiement !== null && (['/01/', '/02/', '/03/', '/04/', '/05/', '/06/',
        '/07/', '/08/', '/09/', '/10/', '/11/', '/12/'].indexOf(facture.datePaiement.slice(2, 6)) === -1)) {
        return true;
      }
    });
  }

}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   /* request = request.clone({
      setHeaders: {
        'Accept': 'application/json',
        'Content-Type': 'text/plain;charset=UTF-8',
        Authorization: `Basic ${btoa('admin:admin')}`
      },
      withCredentials: true
    });*/
    return next.handle(request);
  }
}

import { Component, OnInit } from '@angular/core';
import {EntrepriseService} from '../../entities/entreprise/entreprise.service';
import {CompteService} from "../../entities/compte/compte.service";
import {forkJoin} from "rxjs";
import {FactureService} from "../../entities/facture/facture.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  password: any;
  login: any;
  hasError: any;

  constructor(private entrepriseService: EntrepriseService,
              private compteService: CompteService,
              private router: Router,
              private factureService: FactureService) { }

  ngOnInit() {
  }

  /**
   * Connecte l'entreprise et récupere les comptes et factures
   */
  connect() {
    const compteObs = this.compteService.query();
    this.entrepriseService.loginAttempt = this.login;
    this.entrepriseService.login(this.login, this.password).subscribe(data => {
      if (data !== null) {
        const facturesObs = this.factureService.findByEntreprise(data.id, data.token);
        this.entrepriseService.entreprise = data;
        this.hasError = false;
        forkJoin([compteObs, facturesObs]).subscribe(([comptes, factures]) => {
          this.compteService.comptes = comptes.body;
          this.factureService.factures = factures.body.filter(e => e.statut.toString() !== 'ARCHIVED' );
          // Get rates
          this.factureService.findRate().subscribe(rate => {
            this.factureService.rates = {};
            if (rate.body) {
              rate.body.map(e => {
                if (e.libelle && e.rate) {
                  this.factureService.rates[e.libelle.slice(8, 18)] = e.rate;
                }
              });
            }
            // Redirige vers le dashboard
            this.router.navigate(['/dashboard']);
          }, error => {
            this.hasError = true;
          });
        }, error => {
          this.hasError = true;
        });
      } else {
        this.hasError = true;
      }
    }, error => {
      this.hasError = true;
    });
  }

  requestResetPassword() {}

}

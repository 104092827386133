import {Component, OnInit, ViewChild} from '@angular/core';
import * as $ from 'jquery';
import {Router} from '@angular/router';
import {EntrepriseService} from '../../entities/entreprise/entreprise.service';
import {FactureService} from '../../entities/facture/facture.service';
import {SERVER_API_URL, SERVER_EXPORT_URL} from '../../app.constants';
import {FileUpload} from "primeng";
import {Facture} from "../../entities/model/facture.model";
import {TypeFacture} from "../../entities/model/enumerations/type-facture.model";
import {StatutFacture} from "../../entities/model/enumerations/statut-facture.model";
import {forkJoin, Observable} from "rxjs";


@Component({
  selector: 'app-imports',
  templateUrl: './imports.component.html',
  styleUrls: ['./imports.component.css']
})
export class ImportsComponent implements OnInit {
  // Liste des documents à importer
  documentToImport: any[] = [];
  SERVER_API_URL: any = SERVER_API_URL;
  SERVER_EXPORT_URL: any = SERVER_EXPORT_URL;
  @ViewChild(FileUpload)
  uploadComponent: FileUpload;
  selectedType: any;
  // Compteur de file
  countFile = 0;
  // Affiche ou non le message de file
  displayFileCount = false;
  private loadingFactures: boolean;

  constructor(private router: Router,
              private entrepriseService: EntrepriseService,
              private factureService: FactureService) { }

  ngOnInit() {
    if (!this.entrepriseService.entreprise) {
      this.router.navigate(['/login']);
    }
  }

  popChoice() {
    $('.choice_popup_wrapper').css('display', 'flex');
  }

  closeFromOutside() {
    if (!$(event.target).closest('.choice_popup').length && !$(event.target).is('.choice_popup')) {
      $('.choice_popup_wrapper').hide();
    }
  }

  close() {
    $('.choice_popup_wrapper').hide();
  }

  /**
   * Evenement de fin de chargement du document sur le serveur
   * @param $event
   */
  onUpload($event: any) {
    console.log($event, $event.originalEvent.body);
    this.countFile--;
    if (this.countFile === 0) {
      this.displayFileCount = false;
    }
    if (!$event.originalEvent.body[0] || $event.originalEvent.body[0] === null) {
      return;
    }
    $event.originalEvent.body.forEach(file => {
      this.documentToImport.push({libelle: file, type: this.selectedType});
    });
    // this.close();
  }

  triggerUpload(type) {
    if (type !== this.selectedType && this.countFile > 0) {
      this.displayFileCount = true;
    } else {
      this.displayFileCount = false;
      this.selectedType = type;
      $('input[type=file]')['click']();
    }
  }

  /**
   * ENREGISTRE LES FACTURES ET EXECUTE L'IA
   */
  computeFacture() {
    // Verifier le quota de facture disponible
    if (this.entrepriseService.entreprise.quotaRestantFactureMoisEnCours - this.documentToImport.length < 0) {
      this.entrepriseService.showError('Le quota de facture pour le mois est épuisé');
      return;
    }
    this.loadingFactures = true;
    // Création des factures à partir des documents
    const tmpFactures = [];
    const currentDate = new Date();
    this.documentToImport.forEach(e => {
      tmpFactures.push({
        ...new Facture(),
        type: TypeFacture[e.type],
        statut: StatutFacture.A_VALIDER,
        libelleDocument: e.libelle,
        libelle: e.libelle.slice(51),
        devise: 'CHF',
        taxe: 0.0,
        dateImport: currentDate.toLocaleDateString() + ' ' + currentDate.toLocaleTimeString(),
        entreprise: this.entrepriseService.entreprise
      });
    });
    // Affiche message d'erreur si probleme
    // Enregistrer les factures
    const id = this.entrepriseService.entreprise.id;
    const token = this.entrepriseService.entreprise.token;
    forkJoin(tmpFactures.map(e => this.factureService.create(e, id, token))).subscribe((...factures: any) => {
      this.loadingFactures = false;
      // Récuperation des factures en Bd
      const facturesObs = this.factureService.findByEntreprise(id, token).subscribe(facturesBd => {
        this.factureService.factures = facturesBd.body.filter(e => e.statut.toString() !== 'ARCHIVED' );
        this.entrepriseService.showSucces('Documents importés !');
        this.documentToImport = [];
        // Mise a jour du quota de facture restant en local
        this.entrepriseService.entreprise.quotaRestantFactureMoisEnCours =
          this.entrepriseService.entreprise.quotaRestantFactureMoisEnCours - tmpFactures.length;
        // Lancement de S3 pour chaques factures
        forkJoin(factures[0].map(facture => this.factureService.launchS3(facture.body.id, token))).subscribe(() => {}, (error) => {
          this.entrepriseService.showError('[3]Erreur d\'analyse, si le probleme persiste, contactez le support');
        });
      }, error => {
        this.loadingFactures = false;
        this.entrepriseService.showError('[2]Erreur d\'importation, si le probleme persiste, contactez le support');
      });
    }, error => {
      this.loadingFactures = false;
      this.entrepriseService.showError('[1]Erreur d\'importation, si le probleme persiste, contactez le support');
    });
  }

  /**
   * Evenement déclencher avant upload
   * @param event
   */
  beforeUpload(event) {
    console.log('Before upload event : ', event);
    this.countFile++;
  }
}

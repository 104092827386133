import {Component, OnDestroy, OnInit} from '@angular/core';
import {EntrepriseService} from '../../entities/entreprise/entreprise.service';
import {Router} from '@angular/router';
import {MessageService} from 'primeng';
import {CompteBankService} from '../../entities/comptebank/compte.service';
import {CompteBank, ICompteBank} from '../../entities/model/comptebank.model';
import {HttpResponse} from '@angular/common/http';
import {ProduitService} from '../../entities/produit/produit.service';
import {IProduit, Produit} from '../../entities/model/produit.model';
import {IEntreprise} from '../../entities/model/entreprise.model';

class Banks {
  id: number;
  libelle: string;
  devise: string;
  compte: string;
  undeletable: boolean;
  solde1: number;
  solde2: number;
  solde3: number;
  entreprise: IEntreprise;
}

@Component({
  selector: 'app-parametres',
  templateUrl: './parametres.component.html',
  styleUrls: ['./parametres.component.css']
})
export class ParametresComponent implements OnInit, OnDestroy {
  display: any;
  displayAbonnement: any;
  displayFacturation: any;
  displayCompteBanque: any;
  displayAddFacture: any;
  displayProduits: any;
  displayAddProduct: any;
  banks: Banks[] = [];
  produits: any[] = [];
  selectedDevise: any = 'CHF';
  messageAjoutCompte: string;

  constructor(public entrepriseService: EntrepriseService,
              private messageService: MessageService,
              private comptebankService: CompteBankService,
              private productService: ProduitService,
              private router: Router) {
    this.banks.push({
      ...new Banks(),
      libelle: 'Banque CHF',
      devise: 'CHF',
      compte: '1020',
      undeletable: true
    }, {
      ...new Banks(),
      libelle: 'Banque EUR',
      devise: 'EUR',
      compte: '1021',
      undeletable: true
    });
  }

  ngOnInit() {
    if (!this.entrepriseService.entreprise) {
      this.router.navigate(['/login']);
      return;
    }
    this.comptebankService.findByEntreprise(this.entrepriseService.entreprise.id).subscribe(comptes => {
      comptes.body.map((e: ICompteBank) => {
        const bank: Banks = {
            ...new Banks(),
            id: e.id,
            libelle: e.libelle,
            devise: e.devise,
            compte: e.compte,
            solde1: e.solde1,
            solde2: e.solde2,
            solde3: e.solde3,
            entreprise: e.entreprise
        };
        this.banks.push(bank);
      });
    });
    // Recuperation des produits de l'entreprise
    this.productService.findByEntreprise(this.entrepriseService.entreprise.id).subscribe((produits: HttpResponse<IProduit[]>) => {
      this.produits = produits.body;
    });
  }


  /**
   * Mailto Developpeur
   */
  mailtodev() {
    const mail = document.createElement('a');
    mail.href = 'mailto:support@tl-fiduciaire.ch?subject=Support%20AccountTL';
    mail.click();
  }

  ngOnDestroy(): void {
    this.display = false;
    this.displayAbonnement = false;
    this.displayFacturation = false;
    this.displayCompteBanque = false;
    this.displayAddFacture = false;
    this.displayProduits = false;
  }

  /**
   * Enregistre les données de parametre de l'entreprise
   * @param type : type prestation
   * @param localisation : pays
   */
  saveDataEntreprise(type: string, localisation: string, entreprise: string) {
    this.entrepriseService.entreprise.typeDePrestation = type;
    this.entrepriseService.entreprise.localisation = localisation;
    this.entrepriseService.entreprise.entreprise = entreprise;
    this.entrepriseService.save(this.entrepriseService.entreprise,
      this.entrepriseService.entreprise.id, this.entrepriseService.entreprise.token).subscribe(() => {
      this.display = false;
      this.messageService.add({severity: 'success', summary: 'Enregistré !', detail: 'Données enregistrées !'});
    }, error => {
      this.messageService.add({severity: 'error', summary: 'Erreur',
        detail: 'Erreur inconnue, si le problème persiste, contactez le support.'});
    });
  }

  /**
   * Ajoute le compte au compte bancaire
   * @param value
   */
  ajouterCompte(value: string) {
    const nbDevise = this.banks.filter(e => e.devise === this.selectedDevise).length;
    if (nbDevise > 3) {
      this.messageAjoutCompte = 'Impossible d\'ajouter plus de compte pour cette devise';
      return;
    }
    this.displayAddFacture = false;
    const bank = {
      ... new Banks(),
      libelle: value,
      devise: this.selectedDevise,
      compte: (this.selectedDevise === 'CHF' ? (1022 + nbDevise * 2) : (1023 + nbDevise * 2)) + '',
      undeletable: false
    };
    const bankAccount: CompteBank = {
      ...new CompteBank(),
      libelle: bank.libelle,
      devise: this.selectedDevise,
      compte: bank.compte,
      solde1: 0,
      solde2: 0,
      solde3: 0,
      entreprise: this.entrepriseService.entreprise
    };
    this.comptebankService.create(bankAccount, this.entrepriseService.entreprise.id,
      this.entrepriseService.entreprise.token).subscribe((newbank: HttpResponse<ICompteBank>) => {
        bank.id = newbank.body.id;
        this.banks.push(bank);
    });
  }

  /**
   * Supprime le compte
   * @param compte
   */
  deleteCompte(compte: string) {
    if (compte === '1020' || compte === '1021') {
      return;
    }
    const compteBank: ICompteBank = this.banks.find(e => e.compte === compte);
    if (!compteBank) {
      return;
    }
    this.comptebankService.delete(compteBank.id, this.entrepriseService.entreprise.token).subscribe(() => {
      this.banks = this.banks.filter(e => e.compte !== compte).filter(e => e);
    }, error => {
      this.messageService.add({severity: 'error', summary: 'Erreur',
        detail: error.error.message});
    });
  }

  /**
   * Supprime le produit
   * @param product
   */
  deleteProduct(productId: string) {
    this.productService.delete(+productId, this.entrepriseService.entreprise.token).subscribe(() => {
      this.produits = this.produits.filter(e => e.id !== +productId).filter(e => e);
    }, error => {
      this.messageService.add({severity: 'error', summary: 'Erreur',
        detail: error.error.message});
    });
  }

  /**
   * Ajoute un produit
   * @param value
   */
  ajouterProduit(value: string) {
    const product = {
      ... new Produit(),
      libelle: value,
      entreprise: this.entrepriseService.entreprise
    };
    this.productService.create(product, this.entrepriseService.entreprise.id,
      this.entrepriseService.entreprise.token).subscribe(() => {
      this.produits.push({id: 1, libelle: value});
      this.displayAddProduct = false;
    });
  }

  /**
   * Maj le compte bank en base de donnée
   */
  updateBank(bank: ICompteBank) {
    this.comptebankService.update(bank, this.entrepriseService.entreprise.id,
      this.entrepriseService.entreprise.token).subscribe(() => {});
  }
}

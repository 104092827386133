import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Facture, IFacture} from "../entities/model/facture.model";
import {TypeFacture} from "../entities/model/enumerations/type-facture.model";
import {Router} from "@angular/router";
import {EntrepriseService} from "../entities/entreprise/entreprise.service";
import {MessageService} from "primeng";

@Component({
  selector: 'app-facture-table',
  templateUrl: './facture-table.component.html',
  styleUrls: ['./facture-table.component.css']
})
export class FactureTableComponent implements OnInit {

  _factures = [];
  @Input()
  set factures(value) {
    this._factures = value;
    this.factureSplittedByTrimester = {};
    if (!this.trimester) {
      const previousyear = new Date().getFullYear() - 1;
      this.factureSplittedByTrimester['Autres'] = [];
      this._factures.forEach(e => {
        if (!e.datePaiement || e.datePaiement.length !== 10 || (e.datePaiement.split('/').length !== 3)) {
          this.factureSplittedByTrimester['Autres'].push(e);
        } else {
          const dateSplitted = e.datePaiement.split('/');
          if (+dateSplitted[2] === +previousyear) {
            if (['01', '02', '03'].includes(dateSplitted[1])) {
              this.factureSplittedByTrimester[dateSplitted[2] + ' T1'] =
                (this.factureSplittedByTrimester[dateSplitted[2] + ' T1'] || []).concat([e]);
            } else if (['04', '05', '06'].includes(dateSplitted[1])) {
              this.factureSplittedByTrimester[dateSplitted[2] + ' T2'] =
                (this.factureSplittedByTrimester[dateSplitted[2] + ' T2'] || []).concat([e]);
            } else if (['07', '08', '09'].includes(dateSplitted[1])) {
              this.factureSplittedByTrimester[dateSplitted[2] + ' T3'] =
                (this.factureSplittedByTrimester[dateSplitted[2] + ' T3'] || []).concat([e]);
            } else if (['10', '11', '12'].includes(dateSplitted[1])) {
              this.factureSplittedByTrimester[dateSplitted[2] + ' T4'] =
                (this.factureSplittedByTrimester[dateSplitted[2] + ' T4'] || []).concat([e]);
            }
          }
        }
      });
    }
    this.factureSplittedByTrimester = Object.keys(this.factureSplittedByTrimester).sort().reduce(
      (obj, key) => {
        obj[key] = this.factureSplittedByTrimester[key];
        return obj;
      },
      {}
    );
  }

  get factures() {
    return this._factures;
  }
  @Input() rates = {};
  @Input() title = '-';
  @Input() trimester;

  @Output() sortBy = new EventEmitter<string>();
  @Output() goToFactureModal = new EventEmitter<string>();

  // All factures splitted by trimester
  factureSplittedByTrimester = {};

  year = new Date().getFullYear();
  sortOrder = false;
  displayValidation = false;
  isValidated = false;
  facturesEdited = {};

  constructor(private router: Router,
              private entrepriseService: EntrepriseService,
              private messageService: MessageService) { }

  ngOnInit() {
    if (this.trimester === '1') {
      this.isValidated = +this.entrepriseService.entreprise.trimester1 === +this.year;
    }
    if (this.trimester === '2') {
      this.isValidated = +this.entrepriseService.entreprise.trimester2 === +this.year;
    }
    if (this.trimester === '3') {
      this.isValidated = +this.entrepriseService.entreprise.trimester3 === +this.year;
    }
    if (this.trimester === '4') {
      this.isValidated = +this.entrepriseService.entreprise.trimester4 === +this.year;
    }
  }

  sortByEvent(event: string) {
    this.sortBy.emit(event);
    this.sortOrder = !this.sortOrder;
    console.log(this.factureSplittedByTrimester);
    if (this.title === 'Anciennes factures') {
      Object.keys(this.factureSplittedByTrimester).map(e => {
        if (this.factureSplittedByTrimester[e]) {
          this.sortFactures(this.factureSplittedByTrimester[e], event);
        }
      });
    } else {
      this.sortFactures(this.factures, event);
    }
  }

  private sortFactures(list, event: string) {
    if (event === 'Date paiement') {
      list.sort((a, b) => {
        if (!a.datePaiement || a.datePaiement.split('/').length !== 3) {
          return (this.sortOrder ? 1 : -1);
        }
        if (!b.datePaiement || b.datePaiement.split('/').length !== 3) {
          return -1 * (this.sortOrder ? 1 : -1);
        }
        return (this.getDateFromFrFormat(a.datePaiement).getTime() - this.getDateFromFrFormat(b.datePaiement).getTime())
          * (this.sortOrder ? 1 : -1);
      });
    } else if (event === 'Date facture') {
      list.sort((a, b) => {
        if (!a.dateFacture || a.dateFacture.split('/').length !== 3) {
          return (this.sortOrder ? 1 : -1);
        }
        if (!b.dateFacture || b.dateFacture.split('/').length !== 3) {
          return -1 * (this.sortOrder ? 1 : -1);
        }
        return (this.getDateFromFrFormat(a.dateFacture).getTime() - this.getDateFromFrFormat(b.dateFacture).getTime())
          * (this.sortOrder ? 1 : -1);
      });
    } else if (event === 'Montant') {
      list.sort((a, b) => {
        return (this.sortOrder ? 1 : -1) * (a.montantTotal - b.montantTotal);
      });
    } else {
      list.sort((a, b) => {
        if (!a.dateImport || a.dateImport.split('/').length !== 3) {
          return (this.sortOrder ? 1 : -1);
        }
        if (!b.dateImport || b.dateImport.split('/').length !== 3) {
          return -1 * (this.sortOrder ? 1 : -1);
        }
        return (this.getDateFromFrFormat(a.dateImport).getTime() - this.getDateFromFrFormat(b.dateImport).getTime())
          * (this.sortOrder ? 1 : -1);
      });
    }
  }

  getDateFromFrFormat(date) {
    if (date) {
      const datearray = date.split('/');
      return new Date(datearray[1] + '/' + datearray[0] + '/' + datearray[2]);
    }
    return new Date();
  }

  /**
   * Arrondi un nombre
   * @param number
   */
  round(number: number) {
    return Math.round(number);
  }


  getRateFromDate(date: string) {
    if (date && this.rates) {
      return this.rates[date] || 1.1;
    }
    return 1.1;
  }

  /**
   * Retourne l'affichage du taux dans la case TVA
   */
  getFactureTaxe(facture: IFacture) {
    if (facture.type.toString() !== TypeFacture[TypeFacture.Vente] && facture.type.toString() !== TypeFacture[TypeFacture.Achat]) {
      return 'NA';
    }
    return facture.taxe ? facture.taxe + ' %' : '';
  }

  /**
   * Récupere le nom du statut à afficher
   */
  getStatut(statut) {
    return statut.split('_').join(' ');
  }

  /**
   * Navigue vers la vue d'edition de la facture
   * @param id
   */
  goToFacture(id) {
    this.facturesEdited[id] = true;
    this.goToFactureModal.emit(id);
    // Old: this.router.navigate(['/facture/' + id]);
  }

  updateEntreprise() {
    if (this.trimester === '1') {
      this.entrepriseService.entreprise.trimester1 = '' + this.year;
    }
    if (this.trimester === '2') {
      this.entrepriseService.entreprise.trimester2 = '' + this.year;
    }
    if (this.trimester === '3') {
      this.entrepriseService.entreprise.trimester3 = '' + this.year;
    }
    if (this.trimester === '4') {
      this.entrepriseService.entreprise.trimester4 = '' + this.year;
    }
    this.entrepriseService.save(this.entrepriseService.entreprise,
      this.entrepriseService.entreprise.id, this.entrepriseService.entreprise.token).subscribe(() => {
      this.displayValidation = false;
      this.isValidated = true;
      this.entrepriseService.notification(this.entrepriseService.entreprise.id, this.trimester, this.entrepriseService.entreprise.token)
        .subscribe(() => {
          this.messageService.add({severity: 'success', summary: 'Enregistré !', detail: 'Le trimestre a été validé.'});
        }, () => {
          this.messageService.add({severity: 'error', summary: 'Erreur',
            detail: 'Erreur inconnue, si le problème persiste, contactez le support.'});
        });
    }, error => {
      this.messageService.add({severity: 'error', summary: 'Erreur',
        detail: 'Erreur inconnue, si le problème persiste, contactez le support.'});
    });
  }
}

import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { FacturesComponent } from './pages/factures/factures.component';
import { FactureComponent } from './pages/facture/facture.component';
import { ImportsComponent } from './pages/imports/imports.component';
import { ParametresComponent } from './pages/parametres/parametres.component';
import {AuthGuard} from './auth-guard.service';
import {CompteService} from './entities/compte/compte.service';
import {EntrepriseService} from './entities/entreprise/entreprise.service';
import {FactureService} from './entities/facture/facture.service';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TokenInterceptor} from './token.interceptor';
import {
    AccordionModule,
    DialogModule, DropdownModule,
    FileUploadModule, InputMaskModule, InputTextModule,
    MessageService, ProgressSpinnerModule,
    TableModule, TabViewModule,
    ToastModule, ToggleButtonModule,
    TooltipModule
} from 'primeng';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {QrScannerComponent} from './pages/qrscanner/qr-scanner.component';
import {ZXingScannerModule} from './pages/qrscanner/zxing-scanner/src/lib/zxing-scanner.module';
import { DocumentComponent } from './pages/document/document.component';
import { TrimesterFactureFilterPipe } from './trimester-facture-filter.pipe';
import { FactureTableComponent } from './facture-table/facture-table.component';
import {DonneescomptableComponent} from './pages/donneescomptable/donneescomptable.component';

const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  { path: 'factures', component: FacturesComponent },
  { path: 'documents', component: DocumentComponent},
  { path: 'donnees-comptable', component: DonneescomptableComponent},
  { path: 'facture/:id', component: FactureComponent },
  { path: 'imports', component: ImportsComponent },
  { path: 'parametres', component: ParametresComponent },
  { path: 'login', component: LoginComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    FacturesComponent,
    QrScannerComponent,
    FactureComponent,
    ImportsComponent,
    ParametresComponent,
    DocumentComponent,
    DonneescomptableComponent,
    TrimesterFactureFilterPipe,
    FactureTableComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        DropdownModule,
        HttpClientModule,
        TooltipModule,
        DialogModule,
        ToggleButtonModule,
        ToastModule,
        ZXingScannerModule,
        TableModule,
        AccordionModule,
        RouterModule.forRoot(
            routes
        ),
        ReactiveFormsModule,
        FormsModule,
        FileUploadModule,
        InputMaskModule,
        TabViewModule,
        ProgressSpinnerModule,
        InputTextModule
    ],
  exports: [QrScannerComponent],
  providers: [AuthGuard, CompteService, EntrepriseService, FactureService, MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }

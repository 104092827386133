import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import {SERVER_API_URL} from '../../app.constants';
import {Compte, ICompte} from '../model/compte.model';
import {CompteBank, ICompteBank} from '../model/comptebank.model';
import {IProduit} from '../model/produit.model';

type EntityResponseType = HttpResponse<IProduit>;
type EntityArrayResponseType = HttpResponse<IProduit[]>;

@Injectable({ providedIn: 'root' })
export class ProduitService {
  produits: IProduit[] = [];
  public resourceUrl = SERVER_API_URL + 'produit';

  constructor(protected http: HttpClient) {}

  create(facture: IProduit, id, token): Observable<EntityResponseType> {
    return this.http.post<IProduit>(this.resourceUrl + '/' + id + '/' + token, facture, { observe: 'response' });
  }

  update(compte: IProduit): Observable<EntityResponseType> {
    return this.http.put<IProduit>(this.resourceUrl, compte, { observe: 'response' });
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IProduit>(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  findByEntreprise(id: number): Observable<EntityArrayResponseType> {
    return this.http.get<IProduit[]>(`${this.resourceUrl}/entreprise/${id}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    return this.http.get<IProduit[]>(this.resourceUrl, {observe: 'response' });
  }

  delete(id: number, token: string): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}/${token}`, { observe: 'response' });
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {FactureService} from '../../entities/facture/facture.service';
import {EntrepriseService} from '../../entities/entreprise/entreprise.service';
import {Facture, IFacture} from '../../entities/model/facture.model';
import {TypeFacture} from '../../entities/model/enumerations/type-facture.model';
import {QrScannerComponent} from '../qrscanner/qr-scanner.component';
import {BehaviorSubject} from 'rxjs';
import { BarcodeFormat } from '@zxing/library';

@Component({
  selector: 'app-factures',
  templateUrl: './factures.component.html',
  styleUrls: ['./factures.component.css']
})
export class FacturesComponent implements OnInit {
  // Filtre a la volé des factures
  filtre = '';
  facturesFiltered: Facture[] = [];
  // Nombre de résultat à afficher
  maxFilter = 999999;
  typeFactureFilter = '';
  factureStateFilter = '';
  // Liste des currency a afficher
  currencyToDisplay = ['CHF', 'EUR'];
  // Taux EUR CHF
  rateEURCHF = 1.05;
  @ViewChild(QrScannerComponent) qrScannerComponent: QrScannerComponent ;
  displayQrCodeScanner: any = false;
  /** Facture en train de scanner le QR Code **/
  private currentEditedQRCodeFacture: Facture;

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  hasDevices: boolean;
  hasPermission: boolean;

  qrResultString: string;

  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;
  private sortOrder = true;
  rates = {};
  displayFacture: number | boolean = 0;
  typePaiementFilter = '';

  constructor(private router: Router,
              private entrepriseService: EntrepriseService,
              public factureService: FactureService) {
  }

  ngOnInit() {
    if (!this.entrepriseService.entreprise) {
      this.router.navigate(['/login']);
      return;
    }
    this.factureService.findByEntreprise(this.entrepriseService.entreprise.id, this.entrepriseService.entreprise.token)
      .subscribe(factures => {
        this.factureService.factures = factures.body.filter(e => e.statut.toString() !== 'ARCHIVED' );
        this.facturesFiltered = this.factureService.factures.slice();
        this.sortFactures();
        this.sortBy('Date facture');
      });
  }

  goToFacture(id) {
    this.router.navigate(['/facture/' + id]);
  }

  /**
   * Filtre les factures affichées
   */
  filter() {
    this.facturesFiltered = this.factureService.factures.slice()
      .filter(e => {
        if (this.typeFactureFilter === '') {
          return e;
        }
        if (e.type.toString() === this.typeFactureFilter) {
          return e;
        }
        return;
      })
      .filter(e => e)
      .filter(e => {
      return (
        (e.libelleDocument || '') + ' ' + (e.montantTotal || '') +  ' ' + (e.libelle || '') + ' '
        + (e.statut || '') + ' ' + (e.dateImport || '') + ' ' + (e.id || '')
        + ' ' + (e.dateFacture || '') + (e.compte ? (e.compte.code) : '')
      )
        .toLowerCase().indexOf(this.filtre.toLowerCase()) !== -1;
    }).filter(e => {
      // Filtre par état
      if (this.factureStateFilter && this.factureStateFilter !== '') {
        return this.factureStateFilter === e.statut.toString();
      }
      return true;
    }).filter(e => {
      if (!this.typePaiementFilter || this.typePaiementFilter === '') {
        return true;
      }
      return e.typePaiement === this.typePaiementFilter;
      })
      .slice(0, this.maxFilter);
    this.sortFactures();
  }

  /**
   * Tri les factures en mettant les "A valider" en haut
   */
  sortFactures() {
    this.facturesFiltered.sort((a, b) => {
      if (a.statut.toString() === 'A_VALIDER' && b.statut.toString() !== 'A_VALIDER') {
        return -1;
      } else if (a.statut.toString() !== 'A_VALIDER' && b.statut.toString() === 'A_VALIDER') {
        return 1;
      } else {
        return 0;
      }
    });
  }

  /**
   * Filtre l'état des factures affichées
   */
  filterRecord(State: any) {
    this.factureStateFilter = State;
    this.filter();
  }


  /**
   * Ajoute/Enleve une currency a afficher pour les factures
   * @param currency
   */
  displayCurrency(currency) {
    this.filter();
    if (this.currencyToDisplay.indexOf(currency) === -1) {
      this.currencyToDisplay.push(currency);
    } else {
      this.currencyToDisplay[this.currencyToDisplay.indexOf(currency)] = '';
    }
    this.currencyToDisplay = this.currencyToDisplay.filter(e => e);
    this.facturesFiltered = this.facturesFiltered.filter(e => {
      if (this.currencyToDisplay.indexOf(e.devise) !== -1) {
        return e;
      }
    });
  }

  sortBy(libelle: string) {
    this.sortOrder = !this.sortOrder;
    if (libelle === 'Date paiement') {
      this.facturesFiltered.sort((a, b) => {
        if (!a.datePaiement || a.datePaiement.split('/').length !== 3) {
          return (this.sortOrder ? 1 : -1);
        }
        if (!b.datePaiement || b.datePaiement.split('/').length !== 3) {
          return -1 * (this.sortOrder ? 1 : -1);
        }
        return (this.getDateFromFrFormat(a.datePaiement).getTime() - this.getDateFromFrFormat(b.datePaiement).getTime())
          * (this.sortOrder ? 1 : -1);
      });
    } else if (libelle === 'Date facture') {
      this.facturesFiltered.sort((a, b) => {
        if (!a.dateFacture || a.dateFacture.split('/').length !== 3) {
          return (this.sortOrder ? 1 : -1);
        }
        if (!b.dateFacture || b.dateFacture.split('/').length !== 3) {
          return -1 * (this.sortOrder ? 1 : -1);
        }
        return (this.getDateFromFrFormat(a.dateFacture).getTime() - this.getDateFromFrFormat(b.dateFacture).getTime())
          * (this.sortOrder ? 1 : -1);
      });
    } else if (libelle === 'Montant') {
      this.facturesFiltered.sort((a, b) => {
        return (this.sortOrder ? 1 : -1) * (a.montantTotal - b.montantTotal);
      });
    } else {
      this.facturesFiltered.sort((a, b) => {
        if (!a.dateImport || a.dateImport.split('/').length !== 3) {
          return (this.sortOrder ? 1 : -1);
        }
        if (!b.dateImport || b.dateImport.split('/').length !== 3) {
          return -1 * (this.sortOrder ? 1 : -1);
        }
        return (this.getDateFromFrFormat(a.dateImport).getTime() - this.getDateFromFrFormat(b.dateImport).getTime())
          * (this.sortOrder ? 1 : -1);
      });
    }
  }

  getDateFromFrFormat(date) {
    if (date) {
      const datearray = date.split('/');
      return new Date(datearray[1] + '/' + datearray[0] + '/' + datearray[2]);
    }
    return new Date();
  }

  getDateSignedNumber(date) {
    if (date) {
      const datearray = date.split('/');
      return datearray[2] + (datearray[1] / 10) + (datearray[0] / 1000);
    }
    return 0;
  }

  formatDateToEnDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  updateFactures(facture: any) {
    this.facturesFiltered = this.facturesFiltered.map(e => {
      if (e.id === facture.id) {
        e = Object.assign(e, facture);
      }
      return e;
    });
  }
}

import { Component, OnInit } from '@angular/core';
import {SERVER_API_URL} from '../../app.constants';
import {DocumentService} from '../../entities/document/document.service';
import {EntrepriseService} from '../../entities/entreprise/entreprise.service';
import {IDocument} from '../../entities/model/document.model';
import {ProduitService} from '../../entities/produit/produit.service';
import {Router} from '@angular/router';
import * as $ from 'jquery';
import {MessageService} from 'primeng';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {
  SERVER_API_URL = SERVER_API_URL;
  documents: any[] = [];
  private documentToImport: any[] = [];
  trimestres = [
    {label: '', id: ''},
    {label: 'T1', id: 'T1'},
    {label: 'T2', id: 'T2'},
    {label: 'T3', id: 'T3'},
    {label: 'T4', id: 'T4'}
  ];
  categories = [
    {label: 'autre', id: ''},
    {label: 'espece', id: 'espece'},
    {label: 'carte de credit', id: 'carte de credit'},
    {label: 'banque', id: 'banque'},
    {label: 'personnel', id: 'personnel'}
  ];
  constructor(private documentService: DocumentService,
              private router: Router,
              private messageService: MessageService,
              private entrepriseService: EntrepriseService) { }

  ngOnInit() {
    if (!this.entrepriseService.entreprise) {
      this.router.navigate(['/login']);
    }
    this.loadDocuments();
  }

  loadDocuments() {
    this.documentService.findByEntreprise(this.entrepriseService.entreprise.id).subscribe(documents => {
      this.documents = documents.body.map(e => {
        if (e.trimestre) {
          e.trimestre = this.trimestres.find(f => f.id === e.trimestre);
        }
        if (e.categorie) {
          e.categorie = this.categories.find(f => f.id === e.categorie);
        }
        return e;
      });
    });
  }
  /**
   * Evenement de fin de chargement du document sur le serveur
   * @param $event
   */
  onUpload($event: any) {
    if (!$event.originalEvent.body[0] || $event.originalEvent.body[0] === null) {
      return;
    }
    $event.originalEvent.body.forEach(file => {
      this.documentToImport.push({libelle: file, entreprise: this.entrepriseService.entreprise});
    });
    this.documentToImport.forEach(e => {
      this.documentService.create(e, this.entrepriseService.entreprise.id, this.entrepriseService.entreprise.token)
        .subscribe(document => {
        this.documents.push(document.body);
      });
    });
    this.documentToImport = [];
  }

  triggerUpload(type) {
      $('input[type=file]')['click']();
  }

  updateDocumentData(documentdata: any, label: string, event: any) {
    const document = JSON.parse(JSON.stringify(documentdata));
    document[label] = label === 'annee' ? event.target.value : event.selectedOption;
    if (document.trimestre && document.trimestre.label) {
      document.trimestre = document.trimestre.label;
    }
    if (document.categorie && document.categorie.label) {
      document.categorie = document.categorie.label;
    }
    this.documentService.create(document, this.entrepriseService.entreprise.id, this.entrepriseService.entreprise.token).subscribe(() => {
      this.messageService.add({severity: 'success', summary: 'OK', detail: 'Le document a été modifié'});
      this.documents = this.documents.map(e => {
        if (e.id === document.id) {
          Object.assign(e, document);
          e.trimestre = this.trimestres.find(f => f.id === e.trimestre);
          e.categorie = this.categories.find(f => f.id === e.categorie);
        }
        return e;
      });
    }, () => {
      this.messageService.add({severity: 'error', summary: 'Erreur', detail: 'Le document n\'a pas été modifié'});
    });
  }
}

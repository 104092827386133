import { IEntreprise } from './entreprise.model';
import { ICompte } from './compte.model';
import { TypeFacture } from './enumerations/type-facture.model';
import { StatutFacture } from './enumerations/statut-facture.model';
import {IProduit, Produit} from './produit.model';

export interface IFacture {
  id?: number;
  numero?: string;
  type?: TypeFacture;
  statut?: StatutFacture;
  libelleDocument?: string;
  infoDocumentRetourIA?: string;
  libelle?: string;
  dateFacture?: string;
  dateImport?: string;
  devise?: string;
  typePaiement?: string;
  datePaiement?: string;
  montantTotal?: number;
  taxe?: number;
  entreprise?: IEntreprise;
  compte?: ICompte;
  compteCredit?: ICompte;
  codeCompteVatWinbiz?: string;
  qrCodeData?: string;
  produit?: IProduit;
}

export class Facture implements IFacture {
  constructor(
    public id?: number,
    public numero?: string,
    public type?: TypeFacture,
    public statut?: StatutFacture,
    public libelleDocument?: string,
    public infoDocumentRetourIA?: string,
    public libelle?: string,
    public dateFacture?: string,
    public dateImport?: string,
    public devise?: string,
    public typePaiement?: string,
    public datePaiement?: string,
    public montantTotal?: number,
    public taxe?: number,
    public entreprise?: IEntreprise,
    public compte?: ICompte,
    public compteCredit?: ICompte,
    public codeCompteVatWinbiz?: string,
    public qrCodeData?: string,
    public produit?: IProduit
  ) {}
}

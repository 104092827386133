import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';

import {SERVER_API_URL} from '../../app.constants';
import {Entreprise, IEntreprise} from '../model/entreprise.model';
import {MessageService} from 'primeng';

type EntityResponseType = HttpResponse<IEntreprise>;
type EntityArrayResponseType = HttpResponse<IEntreprise[]>;

@Injectable({ providedIn: 'root' })
export class EntrepriseService {
  public resourceUrl = SERVER_API_URL + 'entreprise';
  entreprise: Entreprise;
  isAdmin = false;
  // Login utilisé pour la connection
  loginAttempt: any;

  constructor(protected http: HttpClient, private messageService: MessageService) {}

  create(entreprise: IEntreprise): Observable<EntityResponseType> {
    return this.http.post<IEntreprise>(this.resourceUrl, entreprise, { observe: 'response' });
  }

  update(entreprise: IEntreprise): Observable<EntityResponseType> {
    return this.http.put<IEntreprise>(this.resourceUrl, entreprise, { observe: 'response' });
  }


  login(clientId?, code?): Observable<any> {
    if (clientId.slice(0, 2) === '$$') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    return this.http.get(`${SERVER_API_URL}entreprise/login/` + btoa(clientId + ':' + code));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http.get<IEntreprise>(`${this.resourceUrl}/${id}`, { observe: 'response'});
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    return this.http.get<IEntreprise[]>(this.resourceUrl, { observe: 'response' });
  }

  notification(id, trimester, token): Observable<EntityArrayResponseType> {
    return this.http.get<IEntreprise[]>(this.resourceUrl + '/notifications/' + id + '/' + trimester + '/' + token, { observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  showError(details) {
    this.messageService.add({severity: 'error', summary: 'Erreur', detail: details});
  }
  showSucces(details) {
    this.messageService.add({severity: 'success', summary: 'Succès', detail: details});
  }

  save(entreprise: Entreprise, id: number, token: string) {
    return this.http.put<IEntreprise>(this.resourceUrl + '/' + id + '/' + token, entreprise, { observe: 'response' });
  }
}

import { Component, OnInit } from '@angular/core';
import {SERVER_API_URL} from '../../app.constants';
import {DocumentService} from '../../entities/document/document.service';
import {EntrepriseService} from '../../entities/entreprise/entreprise.service';
import {IDocument} from '../../entities/model/document.model';
import {ProduitService} from '../../entities/produit/produit.service';
import {Router} from '@angular/router';
import * as $ from 'jquery';
import {MessageService} from "primeng";

@Component({
  selector: 'app-document',
  templateUrl: './donneescomptable.component.html',
  styleUrls: ['./donneescomptable.component.css']
})
export class DonneescomptableComponent implements OnInit {
  SERVER_API_URL = SERVER_API_URL;
  documents: any[] = [];
  private documentToImport: any[] = [];
  categories: any[] = [
    {label: 'Achat', id: 'achat', hasYear: true},
    {label: 'Vente', id: 'vente', hasYear: true},
    {label: 'Espèce', id: 'espece', hasYear: true},
    {label: 'Carte de crédit', id: 'carte', hasYear: true},
    {label: 'Compte personnel', id: 'personnel', hasYear: true},
    {label: 'Vente en attente de paiement au 31/12', id: 'vente3112', hasYear: false},
    {label: 'Achat en attente de paiement au 31/12', id: 'achat3112', hasYear: false}
  ];
  private currentCategorie: any;
  private currentYear: any;
  years = [];
  displayModalYear: any;
  loadingScreen: boolean;

  constructor(private documentService: DocumentService,
              private router: Router,
              private messageService: MessageService,
              private entrepriseService: EntrepriseService) {
    const currentYear = new Date().getFullYear();
    this.years = [];
    for (let i = 2020; i <= currentYear; i++) {
      this.years.push({label: i, value: i});
    }
    this.years = this.years.slice().reverse();
    this.currentYear = this.years[0];
  }

  ngOnInit() {
    if (!this.entrepriseService.entreprise) {
      this.router.navigate(['/login']);
    }
    this.documentService.findDocComptableByEntreprise(this.entrepriseService.entreprise.id).subscribe(documents => {
      this.documents = documents.body || [];
      console.log(this.documents);
    });
  }

  /**
   * Evenement de fin de chargement du document sur le serveur
   * @param $event
   */
  onUpload($event: any) {
    if (!$event.originalEvent.body[0] || $event.originalEvent.body[0] === null) {
      return;
    }
    $event.originalEvent.body.forEach(file => {
      this.documentToImport.push({libelle: file, entreprise: this.entrepriseService.entreprise,
        categorie: this.currentCategorie, annee: '' + this.currentYear.value});
    });
    let isUploaded = false;
    this.documentToImport.forEach(e => {
      this.documentService.createcomptable(e, this.entrepriseService.entreprise.id, this.entrepriseService.entreprise.token)
        .subscribe(document => {
        this.documents.push(document.body);
          this.loadingScreen = false;
        if (!isUploaded) {
          isUploaded = true;
          this.messageService.add({severity: 'success', summary: 'OK', detail: 'Le document a été ajouté'});
        }
      });
    });
    this.documentToImport = [];
  }

  triggerUpload(categorie?) {
    this.displayModalYear = false;
    if (categorie) {
      this.currentCategorie = categorie;
    }
    $('input[type=file]')['click']();
  }

  getDocumentsByCategorie(categorie: any) {
    return (this.documents || []).filter(e => e.categorie === categorie);
  }

  beforeUpload($event: any) {
    this.loadingScreen = true;
    this.messageService.add({severity: 'warn', summary: '', detail: 'Ajout du document en cours... veuillez patienter'});
  }
}

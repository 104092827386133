import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FactureService} from '../../entities/facture/facture.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CompteService} from '../../entities/compte/compte.service';
import {EntrepriseService} from '../../entities/entreprise/entreprise.service';
import {Facture} from '../../entities/model/facture.model';
import {SERVER_API_URL} from '../../app.constants';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {MessageService} from 'primeng';
import {StatutFacture} from '../../entities/model/enumerations/statut-facture.model';
import {ICompteBank} from '../../entities/model/comptebank.model';
import {CompteBankService} from '../../entities/comptebank/compte.service';
import {ProduitService} from '../../entities/produit/produit.service';
import {HttpResponse} from '@angular/common/http';
import {IProduit} from '../../entities/model/produit.model';

interface TvaAccounts {
  libelle: string;
  code: string;
  taux: string;
}

@Component({
  selector: 'app-facture',
  templateUrl: './facture.component.html',
  styleUrls: ['./facture.component.css']
})
export class FactureComponent implements OnInit {
  @Input() factureId;
  @Output() factureUpdate = new EventEmitter();
  facture: Facture;
  // Type de facture
  factureType: any = 'Vente';
  compteCreditId: any;
  compteDebitId: any;
  isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  // Ressource à charger
  public ressourceURL: SafeHtml;
  // Type de paiement
  private factureTypePaiement = 'Banque CHF';
  taxeMontant: any = 0;
  accountError: any;
  // Liste des comptes banque personnalisés
  banks: any = [];
  // Compte de tva winbiz
  tvaAccounts: TvaAccounts[] = [
    {
      libelle: '0 - Pas de TVA',
      code: '0',
      taux: '0'
    },
    {
      libelle: '405 - Achats taux normal invest. et autres charges',
      code: '405',
      taux: '7.70'
    },
    {
      libelle: '405 - Achats TVA 100% invest. et autres charges',
      code: '405',
      taux: '100'
    },
    {
      libelle: '400 - Achats taux réduit matieres et prestations',
      code: '400',
      taux: '2.50'
    },
    {
      libelle: '405 - Achats taux reduit invest et autres charges',
      code: '405',
      taux: '7.70'
    },
    {
      libelle: '400 - Achats taux normal matieres et prestations',
      code: '400',
      taux: '7.70'
    },
    {
      libelle: '220 - Exportations',
      code: '220',
      taux: '0.0'
    },
    {
      libelle: '300 - Prestations au taux normal',
      code: '300',
      taux: '7.70'
    },
    {
      libelle: '310 - Prestations au taux reduit',
      code: '310',
      taux: '2.50'
    }
  ];
  produits: IProduit[] = [];
  tooltipDatePaiement = 'Si la date de paiement est renseignée, la facture sera considerée comme payée et son état passera à "validée".';
  // Id du produit choisi
  produitId: any;
  isPdf = false;

  constructor(private factureService: FactureService,
              public entrepriseService: EntrepriseService,
              private messageService: MessageService,
              private comptebankService: CompteBankService,
              private compteService: CompteService,
              private route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private productService: ProduitService,
              private router: Router) { }

  ngOnInit() {
    if (!this.entrepriseService.entreprise) {
      this.router.navigate(['/login']);
    }
    // Recuperation des produits de l'entreprise
    this.productService.findByEntreprise(this.entrepriseService.entreprise.id).subscribe((produits: HttpResponse<IProduit[]>) => {
      this.produits = produits.body;
    });
    const factureId = this.factureId || this.route.snapshot.params['id'];
    this.factureService.find(factureId, this.entrepriseService.entreprise.token).subscribe(facture => {
      this.facture = facture.body;
      this.produitId = this.facture.produit ? this.facture.produit.id : null;
      this.adapteFactureToForm();
      this.updateMontantTaxe();
      this.isPdf = this.facture.libelleDocument.endsWith('pdf');
      this.ressourceURL = this.sanitizer.bypassSecurityTrustResourceUrl(SERVER_API_URL + '/document/' + this.facture.libelleDocument);
    }, error1 => {
      this.entrepriseService.showError(
        'Une erreure innatendue est appararue lors de l\'affichage de la facture [' + factureId + ']');
      // routerthis.router.navigate(['/factures']);
    });
    // récuperation des comptes banques personnalisés
    this.comptebankService.findByEntreprise(this.entrepriseService.entreprise.id).subscribe(comptes => {
      comptes.body.map((e: ICompteBank) => {
        this.banks.push({
          id: e.id,
          libelle: e.libelle,
          devise: e.devise,
          compte: e.compte,
          solde1: e.solde1,
          solde2: e.solde2,
          solde3: e.solde3,
          entreprise: e.entreprise
        });
      });
    });
  }

  /**
   * Méthode de validation de l'état A_VALIDER
   */
  valider(saveHasValide?) {
    const tmpFacture: Facture = Object.assign({}, this.facture);
    const tmpComptes = this.compteService.comptes ? this.compteService.comptes.slice() : [];
    tmpFacture.compte = null;
    tmpFacture.compteCredit = null;
    tmpFacture.datePaiement = this.facture.datePaiement || null;
    tmpFacture.dateFacture = this.facture.dateFacture || null;
    tmpFacture.produit = this.produitId ? this.produits.find(e => e.id === +this.produitId) : null;
    tmpFacture.type = this.factureType;
    const hasError = this.checkConformite();
    if (hasError) {
      return;
    }
    // Verifie la présence des comptes et du code VAT
    if (saveHasValide) {
      /*if (!(tmpFacture.compteCredit && tmpFacture.compte)) {
        this.messageService.add({severity: 'error', summary: 'Champ incorrect', detail: 'Les comptes comptables ne sont pas renseignés'});
        this.accountError = true;
        return;
      }

      if (!tmpFacture.codeCompteVatWinbiz || tmpFacture.codeCompteVatWinbiz === '') {
        this.messageService.add({severity: 'error', summary: 'Champ incorrect', detail: 'Code TVA winbiz manquant'});
        this.accountError = true;
        return;
      }
      */
      // Si on est sur un type salaire, on met à 0 la TVA
      if (this.factureType === 'Salaire' || this.factureType === 'Taxe') {
        tmpFacture.codeCompteVatWinbiz = '0';
        tmpFacture.taxe = 0;
      }
      if (!tmpFacture.taxe || tmpFacture.codeCompteVatWinbiz === '0 - Pas de TVA') {
        tmpFacture.taxe = 0;
      }
      tmpFacture.codeCompteVatWinbiz = '0';
      tmpFacture.taxe = 0;
    }
    // Passe le statut de la facture à EN_COURS_DE_TRAITEMENT, seulement pour les factures "payés" (date de paiement remplie)
    if (tmpFacture.datePaiement && tmpFacture.datePaiement !== '') {
      this.facture.statut = this.entrepriseService.isAdmin ? StatutFacture.VALIDE : StatutFacture.EN_COURS_DE_TRAITEMENT;
      tmpFacture.statut = this.entrepriseService.isAdmin ? StatutFacture.VALIDE : StatutFacture.EN_COURS_DE_TRAITEMENT;
    }
    // Enregistre la facture en l'etat
    this.factureService
      .update(tmpFacture, this.entrepriseService.entreprise.id, this.entrepriseService.entreprise.token)
      .subscribe(facture => {
      this.facture = facture.body;
      this.adapteFactureToForm();
        if (saveHasValide) {
          this.facture.statut = StatutFacture.VALIDE;
          this.messageService.add({severity: 'success', summary: 'OK',
            detail: 'L\'enregistrement de la facture est validé.'});
          this.messageService.add({severity: 'info', summary: 'WINBIZ',
            detail: 'Diffusion à winbiz en cours ...'});
          this.factureService.winbiz(this.entrepriseService.entreprise.id, '' + this.facture.id,
            this.entrepriseService.entreprise.token, this.entrepriseService.loginAttempt).subscribe((data) => {
              if (data.body) {
                this.messageService.add({severity: 'success', summary: 'OK',
                  detail: 'L\'enregistrement de la facture sur winbiz est validé.'});
                this.facture.statut = StatutFacture.VALIDE;
                this.factureUpdate.emit(this.facture);
              } else {
                this.messageService.add({severity: 'error', summary: 'ERROR',
                  detail: 'L\'enregistrement de la facture dans winbiz a echoué.'});
              }
          });
        } else {
          this.messageService.add({severity: 'success', summary: 'OK',
            detail: 'L\'enregistrement est validé'});
          this.factureUpdate.emit(this.facture);
        }
    }, error => {
        // Remet le statut de la facture au statut précedent avant tentative d'enregistrement
        this.facture.statut = saveHasValide ? StatutFacture.EN_COURS_DE_TRAITEMENT : StatutFacture.A_VALIDER;
        this.messageService.add({severity: 'error', summary: 'Problème d\'enregistrement',
          detail: 'Echec d\'enregistrement, si le problème persiste, contactez le support'});
      });
  }

  /**
   * Récupere la date au format input
   */
  getDateForInput(datePaiement: string) {
    const tmpArrayDays = datePaiement.replace('\\', '/').split('/');
    return tmpArrayDays[2] + '-' + tmpArrayDays[1] + '-' + tmpArrayDays[0] ;
  }

  /**
   * Calcule le montant des taxes
   */
  updateMontantTaxe() {
    this.taxeMontant = (Math.round(100 * (
      (this.facture.montantTotal * (1 + (this.facture.taxe / 100))) - this.facture.montantTotal)) / 100);
  }

  /**
   * Calcule le montant des taxes en pourcentage
   */
  updateMontantTaxePercentage() {
    this.facture.taxe = Math.round((this.taxeMontant / this.facture.montantTotal * 10000)) / 100;
  }
  /**
   * Lance la diffusion à winbiz
   */
  diffusionWinBiz() {
    const hasConformiteError = this.checkConformite();
    if (hasConformiteError) {
      this.messageService.add({severity: 'error', summary: 'Champ incorrect', detail: 'Diffusion impossible avec ces données'});
      return;
    }
    // Enregistre la facture avec le statut valide et la diffuse a winbiz
    this.valider(true);
  }

  /**
   * Verifie la conformité des champs du formulaire
   */
  checkConformite() {
    let hasError = false;
    // La facture est conforme si elle n'a pas de date de paiement
    if (!this.facture.datePaiement || this.facture.datePaiement === '') {
      this.messageService.add({severity: 'info', summary: 'Facture non payé',
        detail: 'La date de paiement n\'est pas renseigné, donc la facture reste en attente de validation'});
      return false;
    }
    // Si date de paiement valide, alors
    // vérifie la conformité des champs
    if (!(this.facture.montantTotal > 0)) {
      this.messageService.add({severity: 'error', summary: 'Champ incorrect', detail: 'Montant total incorrect'});
      hasError = true;
    }
    if (this.facture.taxe) {
      if (this.facture.taxe < 0 || this.facture.taxe > 100) {
        this.messageService.add({severity: 'error', summary: 'Champ incorrect', detail: 'La taxe est incorrect'});
        hasError = true;
      }
    }
    if (!this.facture.libelle || (this.facture.libelle === '')) {
      this.messageService.add({severity: 'error', summary: 'Champ incorrect', detail: 'Libellé incorrect'});
      hasError = true;
    }
    if (!this.facture.numero || this.facture.numero === '') {
      this.messageService.add({severity: 'error', summary: 'Champ incorrect', detail: 'Numéro de facture incorrect'});
      hasError = true;
    }
    if (!this.facture.type) {
      this.messageService.add({severity: 'error', summary: 'Champ incorrect', detail: 'Type de facture incorrect'});
      hasError = true;
    }
    if (!this.facture.typePaiement) {
      this.messageService.add({severity: 'error', summary: 'Champ incorrect', detail: 'Type de paiement incorrect'});
      hasError = true;
    }
    if (!this.facture.dateFacture) {
      this.messageService.add({severity: 'error', summary: 'Champ incorrect', detail: 'Date facture incorrect'});
      hasError = true;
    }
    return hasError;
  }

  /**
   * Adapte les données de la facture pour le formulaire
   */
  adapteFactureToForm() {
    this.factureType = this.facture.type;
    this.factureTypePaiement = this.facture.typePaiement;
    this.compteCreditId = this.facture.compteCredit ? this.facture.compteCredit.id : 0;
    this.compteDebitId = this.facture.compte ? this.facture.compte.id : 0;
    this.facture.datePaiement = this.facture.datePaiement || null;
    this.facture.dateFacture = this.facture.dateFacture || null;
  }

  /**
   * Récupere les comptes de credit a afficher
   */
  getAccountCredit() {
    return this.getComptes().filter(compte => {
      // Retourne pas les comptes non compris dans [3000; 3850] dans le cadre d'une vente
      if ((+compte.code < 3000 || +compte.code > 3850) && this.factureType === 'Vente') {
        return;
      }
      return compte;
    });
  }

  /**
   * Recupere les comptes
   */
  getComptes() {
    return this.compteService.comptes.map(e => {
      if (+e.code >= 1022 && +e.code <= 1029) {
        const compte = this.banks.find(bank => bank.compte === e.code);
        if (compte) {
          e.libelle = compte.libelle;
        }
      }
      return e;
    });
  }

  /**
   * Méthode permettant de changer les comptes de debit/credit automatiquement en fonction du type de paiement selectionné
   */
  computeAccountCode() {
    let code = '0';
    switch (this.facture.typePaiement) {
      case 'Espèce':
          code = '1000';
        break;
      case 'Poste Banque EUR':
        code = '1011';
        break;
      case 'Poste Banque CHF':
        code = '1010';
        break;
      case 'Banque EUR':
        code = '1021';
        break;
      case 'Banque CHF':
        code = '1020';
        break;
      case 'Banque EUR 2':
        code = '1023';
        break;
      case 'Banque CHF 2':
        code = '1022';
        break;
      case 'Compte associe/actionnaire':
        code = '2110';
        break;
      default:
        this.banks.map(e => {
          if (e.libelle === this.facture.typePaiement) {
            code = e.compte;
          }
        });
    }
    if (this.factureType === 'Achat') {
      this.compteCreditId = this.findAccountIdByCode(code);
    } else if (this.factureType === 'Vente') {
      this.compteDebitId = this.findAccountIdByCode(code);
    }
  }

  /**
   * Récupere l'id du compte par son code
   * @param code
   */
  findAccountIdByCode(code) {
    const acc = this.compteService.comptes.find(e => +e.code === +code);
    if (acc) {
      return acc.id;
    } else {
      return 0;
    }
  }

  /**
   * Ouvre le scanner de QR code
   */
  openQrCodeScanner() {

  }

  /**
   * Met la TVA à 0 si selectionné dans le code winbiz
   */
  updateVATIf0() {
    if (this.facture.codeCompteVatWinbiz === '0 - Pas de TVA') {
      this.facture.taxe = 0;
    }
  }

  /**
   * Supprime la facture en lui mettant le statut "ARCHIVED"
   */
  supprimerFacture() {
    this.facture.statut = StatutFacture.ARCHIVED;
    // Enregistre la facture en l'etat
    this.factureService
      .update(this.facture, this.entrepriseService.entreprise.id, this.entrepriseService.entreprise.token)
      .subscribe(facture => {
        this.facture = facture.body;
        this.adapteFactureToForm();
        this.messageService.add({
          severity: 'success', summary: 'OK',
          detail: 'La facture a été archivée.'
        });
        // this.router.navigate(['/factures']);
      }, error => {
        this.messageService.add({
          severity: 'error', summary: 'Error',
          detail: 'La suppression a échouée.'
        });
      });
  }

  getRateFromDate(date: string) {
    if (date && this.factureService.rates) {
      return this.factureService.rates[date] || 1.1;
    }
    return 1.1;
  }

  getEquivalentCHF(montantTotal: number, datePaiement: string) {
    if (montantTotal > 0) {
      const rate = this.getRateFromDate(datePaiement);
      if (rate && rate > 0) {
        return Math.round(rate * montantTotal);
      }
    }
    return 0;
  }

  saveWithoutValidate() {
    const tmpFacture: Facture = Object.assign({}, this.facture);
    const tmpComptes = this.compteService.comptes ? this.compteService.comptes.slice() : [];
    tmpFacture.compte = null;
    tmpFacture.compteCredit = null;
    tmpFacture.datePaiement = this.facture.datePaiement || null;
    tmpFacture.dateFacture = this.facture.dateFacture || null;
    tmpFacture.produit = this.produitId ? this.produits.find(e => e.id === +this.produitId) : null;
    tmpFacture.type = this.factureType;
    const hasError = this.checkConformite();
    if (hasError) {
      return;
    }
    this.facture.statut = StatutFacture.ENREGISTREE;
    tmpFacture.statut = StatutFacture.ENREGISTREE;
    this.factureService
      .update(tmpFacture, this.entrepriseService.entreprise.id, this.entrepriseService.entreprise.token)
      .subscribe(facture => {
        this.facture = facture.body;
        this.adapteFactureToForm();
        this.messageService.add({severity: 'success', summary: 'OK',
          detail: 'L\'enregistrement de la facture est validé.'});
      });
  }
}

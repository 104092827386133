// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application

export const SERVER_EXPORT_URL = 'https://accountl.ch/';


// export const SERVER_API_URL = 'http://localhost:5680/';
export const SERVER_API_URL = 'https://accountl.ch/api/';
// export const SERVER_API_URL = 'http://45.66.222.224:5680/';
// export const SERVER_API_URL = 'http://51.83.33.198:5680/';
// export const SERVER_API_URL = 'http://571040991464.ngrok.io/';

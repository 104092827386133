import {IEntreprise} from './entreprise.model';

export interface IProduit {
  id?: number;
  libelle?: string;
  entreprise?: IEntreprise;
}

export class Produit implements IProduit {
  constructor(public id?: number, public libelle?: string, public entreprise?: IEntreprise) {}
}
